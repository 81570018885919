<template>
  <div class="modal fade" id="consentModal">
    <div
      class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
      data-bs-backdrop="static"
      data-bs-keyboard="true"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ consentModal.label }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body consent-modal-text">
          <span v-html="consentModal.text" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";

export default {
  name: "ConsentDialog",
  data() {
    return {
      consentModal: {
        label: "",
        text: ""
      }
    };
  },
  methods: {
    openConsent(consent, consentText = "") {
      this.consentModal.label = consent?.label || "";
      this.consentModal.text = consentText || consent?.text || "";
      const modal = new Modal(
        document
          .querySelector("travel-suite-widget")
          .shadowRoot.getElementById("consentModal"),
        {}
      );
      modal?.show();
    }
  }
};
</script>
<style scoped></style>
