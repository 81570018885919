<template>
    <div
        class="modal fade"
        id="CarouselModal"
        ref="modal"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <div
                        id="CarouselSlider"
                        class="carousel slide"
                        ref="carousel"
                        data-ride="carousel"
                    >
                        <div class="carousel-inner">
                            <div v-if="media.mainImage" class="carousel-item active">
                                <div
                                    class="picture-wrapper ratio ratio-4x3"
                                    :style="{
                    'background-image':
                      'url(' + (media.mainImage.url + '?d=400x300)')
                  }"
                                />
                            </div>
                            <template v-if="media.mediaList && media.mediaList.length">
                            <div
                                v-bind:key="media.public_id"
                                v-for="media in media.mediaList"
                                class="carousel-item"
                            >
                                <template v-if="media.type == 'pano' && media.url_public">
                                <div class="picture-wrapper ratio ratio-4x3">
                                    <iframe
                                        class="embed-responsive-item"
                                        :src="media.url_public"
                                        allowfullscreen
                                    ></iframe>
                                </div>
                                </template>
                                <template v-else-if="media.type === 'video'">
                                <div class="picture-wrapper ratio ratio-4x3">
                                    <video
                                        v-if="media.provider === 'cdn'"
                                        :poster="media.url"
                                        autoplay
                                        muted
                                        loop
                                        class="embed-responsive-item"
                                    >
                                        <source :src="media.url_public" type="video/mp4"/>
                                    </video>
                                    <iframe
                                        v-else-if="media.provider === 'youtube'"
                                        allowfullscreen
                                        frameborder="0"
                                        :src="
                          'https://www.youtube.com/embed/' +
                          media.public_id +
                          '?autoplay=1&controls=0&crop=off&modestbranding=1&mute=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&hd=1&playlist=' +
                          media.public_id
                        "
                                    />
                                    <iframe
                                        v-else-if="media.provider === 'vimeo'"
                                        class="video-item"
                                        allowfullscreen
                                        :src="
                          'https://player.vimeo.com/video/' +
                          media.public_id +
                          '?autoplay=1&background=1&muted=1&loop=1'
                        "
                                    />
                                </div>
                                </template>
                                <div
                                    v-else
                                    class="picture-wrapper ratio ratio-4x3"
                                    :style="{
                      'background-image': 'url(' + (media.url + '?d=400x300)')
                    }"
                                />
                            </div>
                            </template>
                        </div>
                        <template>
                        <a
                            class="carousel-control-prev"
                            role="button"
                            @click="prev()"
                        >
                <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                ></span>
                        </a>
                        <a
                            class="carousel-control-next"
                            role="button"
                            @click="next()"
                        >
                <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                ></span>
                        </a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {Carousel, Modal} from "bootstrap";

export default {
    name: "CarouselModal",
    data() {
        return {
            carousel: null,
            media: {},
            modal: null,
        }
    },
    methods: {
        open(media) {
            if (!this.modal) {
                this.modal = new Modal(this.$refs.modal);
            }
            if (this.carousel) {
                this.carousel.dispose();
            }
            this.media = media;
            this.carousel = new Carousel(this.$refs.carousel);
            this.carousel.to(0)
            this.modal.show();
        },
        next() {
            this.carousel.next();
        },
        prev() {
            this.carousel.prev();
        }
    }
};
</script>
