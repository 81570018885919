<template>
  <div ref="travelSuiteWidget" class="container-fluid main">
    <div class="row">
      <div class="col vld-parent">
        <loading
          :active.sync="loading"
          :can-cancel="false"
          :is-full-page="false"
        />

        <div
          v-if="loading"
          class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
          style="z-index: 100"
        >
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-if="error" class="alert alert-danger text-center" role="alert">
          {{ error }}
        </div>
        <div v-if="config" :class="{ 'is-mobile': isMobile }">
          <success-message
            v-if="showSuccessMessage"
            :config="config"
            :configType="config.type"
          ></success-message>
          <template v-else>
            <booking-request
              v-if="config.type === 'booking_request'"
              :config="config"
              :settings="settings"
              :isMobile="isMobile"
              v-on:success-event="emitSuccessMessage"
              v-on:widget-event="emitCustomWidgetEvent"
              v-on:datalayer-event="emitDataLayerEvent"
            ></booking-request>
            <newsletter-request
              v-if="config.type === 'newsletter_request'"
              :config="config"
              :settings="settings"
              :isMobile="isMobile"
              v-on:success-event="emitSuccessMessage"
              v-on:widget-event="emitCustomWidgetEvent"
            ></newsletter-request>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./plugins/axios";
import "./plugins/moment";
import Loading from "vue-loading-overlay";
import BookingRequest from "@/components/BookingRequest";
import NewsletterRequest from "@/components/NewsletterRequest";
import SuccessMessage from "@/components/SuccessMessage";
import i18n from "@/plugins/i18n";

window.dataLayer = window.dataLayer || [];

export default {
  i18n: i18n,
  name: "TravelSuiteWidget",
  components: {
    Loading,
    BookingRequest,
    NewsletterRequest,
    SuccessMessage
  },
  props: {
    uuid: String,
    lang: {
      type: String,
      default: "en"
    },

    from_date: String, //yyyy-MM-dd
    to_date: String, //yyyy-MM-dd

    p0_product_code: String,
    p0_mealplan_code: String,
    p0_rateplan_code: String,
    p0_guests: String,

    p1_product_code: String,
    p1_mealplan_code: String,
    p1_rateplan_code: String,
    p1_guests: String,

    p2_product_code: String,
    p2_mealplan_code: String,
    p2_rateplan_code: String,
    p2_guests: String,

    utm_source: String,
    utm_medium: String,
    utm_campaign: String,
    utm_term: String,
    utm_content: String,
    state: Object
  },
  data() {
    return {
      showSuccessMessage: false,
      loading: true,
      error: null,
      config: null,
      settings: {}
    };
  },
  methods: {
    emitSuccessMessage: function () {
      this.showSuccessMessage = true;
      this.$nextTick(() => {
        setTimeout(() => {
          "travelSuiteWidget" in this.$refs &&
            this.$refs.travelSuiteWidget.scrollIntoView({
              block: "center",
              behavior: "smooth"
            });
        }, 1);
      });
    },
    emitCustomWidgetEvent: function (name, detail) {
      if (name === "onStateChanged") {
        this.$root.props.state = detail;
      }
      this.$root.$options.customElement.dispatchEvent(
        new CustomEvent(name, {
          bubbles: false,
          cancelable: false,
          detail: detail
        })
      );
    },
    emitDataLayerEvent: function (detail) {
      window.dataLayer.push(detail);
    },
    initializeSetting: function () {
      var searchParams = new URLSearchParams(window.location.search);
      if (
        searchParams.has("lang") &&
        searchParams.get("lang") != "" &&
        ["de", "en", "fr", "it"].includes(searchParams.get("lang"))
      ) {
        this.$i18n.locale = searchParams.get("lang");
      } else {
        this.$i18n.locale = this.lang;
      }
      Object.keys(this._props).forEach((property) => {
        if (this[property]) this.settings[property] = this[property];

        if (searchParams.has(property))
          this.settings[property] = searchParams.get(property);
      });

      if (
        !searchParams.has("utm_campaign") &&
        localStorage.getItem("__yts_tracking")
      ) {
        try {
          const validity = new Date().getTime() - 21 * 24 * 3600 * 1000; // 21 Days.
          const tracking = JSON.parse(localStorage.getItem("__yts_tracking"));
          if (tracking.last.utm_time && tracking.last.utm_time > validity) {
            this.settings.utm_source = tracking.last.utm_source;
            this.settings.utm_medium = tracking.last.utm_medium;
            this.settings.utm_campaign = tracking.last.utm_campaign;
            this.settings.utm_term = tracking.last.utm_term;
            this.settings.utm_content = tracking.last.utm_content;
          }
        } catch (e) {}
      }
    }
  },
  computed: {
    isMobile: function () {
      return screen.width < 769;
    }
  },
  mounted() {
    this.initializeSetting();
    if (!this.uuid) {
      this.error = "uuid attribute is missing";
      return;
    }

    this.$http
      .get("widget/" + this.uuid, {
        headers: {
          "accept-language": this.$i18n.locale
        }
      })
      .then((response) => {
        this.loading = false;
        this.config = response.data.data;
      })
      .catch((error) => {
        console.log("error", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.error = error.response.data.message;
        } else {
          this.error = "uuid is invalid";
        }
        this.loading = false;
      });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
<style lang="scss">
@import "scss/style";
@import "~vue-loading-overlay/dist/vue-loading.css";
</style>
