import { render, staticRenderFns } from "./TravelSuiteWidget.vue?vue&type=template&id=b49def8e&scoped=true&shadow"
import script from "./TravelSuiteWidget.vue?vue&type=script&lang=js&shadow"
export * from "./TravelSuiteWidget.vue?vue&type=script&lang=js&shadow"
function injectStyles (context) {
  
  var style1 = require("./TravelSuiteWidget.vue?vue&type=style&index=1&lang=scss&shadow")
if (style1.__inject__) style1.__inject__(context)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "b49def8e",
  null
  ,true
)

export default component.exports