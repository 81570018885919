export default {
  datepicker: {
    night: "Nacht",
    nights: "Nächte",
    week: "Woche",
    weeks: "Wochen",
    "day-names": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    "check-in": "Anreise",
    "check-out": "Abreise",
    "month-names": [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "July",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember"
    ],
    tooltip: {
      halfDayCheckIn: "Möglicher Check-In",
      halfDayCheckOut: "Möglicher Check-Out",
      saturdayToSaturday: "Nur von Samstag bis Samstag",
      sundayToSunday: "Nur von Sonntag bis Sonntag",
      minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum."
    },
    format: "DD.MM.YYYY",
    format_placehoder: "TT.MM.JJJJ"
  },
  form: {
    check_in_out: "{check_in}/{check_out}",
    age_child_x: "Alter Kind {x}",
    persons_x: "{min}-{max} Personen",
    mealplan: "Verpflegung",
    board: {
      any: "Beliebige Verpflegung"
    },
    adult_pl: "Erwachsener | Erwachsene",
    child_pl: "Kind | Kinder",
    children: {
      age_x: "Unter 1 Jahr|{count} Jahr |{count} Jahre"
    },
    offer: {
      any: "Beliebiges Angebot",
      offer_pl: "Angebot | Angebote"
    },
    room: {
      any: "Beliebiges Zimmer",
      room_pl: "Zimmer | Zimmer",
      add: "Zimmer hinzufügen",
      remove: "Zimmer entfernen"
    },
    gender: {
      label: "Anrede",
      m: "Herr",
      f: "Frau"
    },
    gender_prefix: {
      label: "Anrede",
      mr: "Herr",
      ms: "Frau",
      family: "Familie",
      doctor: "Herr Dr.",
      doctor_ms: "Frau Dr.",
      ms_mr: "Frau und Herr",
      company: "Unternehmen"
    },
    companyname: "Unternehmensname",
    firstname: "Vorname",
    lastname: "Nachname",
    email: "E-Mail",
    phone: "Telefon",
    street: "Straße",
    zip: "PLZ",
    city: "Ortschaft",
    country_iso: "Land",
    note: "Notiz",
    error: {
      hint_missing: "Bitte füllen Sie '{field}' aus",
      hint_invalid: "Bitte füllen Sie '{field}' korrekt aus",
      consent_mandatory: "Bitte fülen Sie das Pflichtfeld aus"
    },
    submit: "Anfrage senden",
    subscribe_now: "JETZT ANMELDEN",
    messages: {
      request_sent: "Die Anfrage wurde erfolgreich versendet",
      error: "Es ist ein Fehler aufgetreten"
    },
    consent_default_title: "Marketing Einwilligung"
  },
  success_messages: {
    newsletter_request: {
      headline: "Newsletter Anmeldung erfolgreich!",
      context_1:
        "Vielen Dank, dass Sie sich für den Empfang unseres Newsletters angemeldet haben. Sie erhalten in Kürze eine E-Mail. Bitte bestätigen Sie Ihre Anmeldung durch Aufruf des Links."
    },
    booking_request: {
      headline: "Anfrage erfolgreich versendet!",
      context_1: "Vielen Dank für Ihre Anfrage und Ihr Interesse.",
      context_2:
        "Sie erhalten in Kürze unser individuelles Angebot für Ihren Wunschzeitraum."
    }
  },
  default: {
    error_message:
      "Ein unerwarteter Fehler ist aufgetreten.\n Bitte versuchen Sie es später erneut."
  }
};
