<template>
  <div class="widget-body">
    <div
      class="success-message-wrapper d-flex justify-items-center justify-content-center"
    >
      <div class="m-auto d-flex flex-column">
        <div
          class="d-flex success-icon-wrapper justify-items-center justify-content-center"
        >
          <!--                    <svg style="width: 128px; height: 128px;vertical-align: middle;fill: #93c763;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">-->
          <!--                        <path d="M512 938.666667C276.352 938.666667 85.333333 747.648 85.333333 512S276.352 85.333333 512 85.333333s426.666667 191.018667 426.666667 426.666667-191.018667 426.666667-426.666667 426.666667z m0-85.333334a341.333333 341.333333 0 1 0 0-682.666666 341.333333 341.333333 0 0 0 0 682.666666z m-42.538667-170.666666L288.426667 501.632l60.330666-60.330667 120.704 120.704 241.322667-241.365333 60.373333 60.330667L469.461333 682.666667z"/>-->
          <!--                    </svg>-->
          <img src="@/assets/ok.png" width="128px" height="128px" alt="" />
        </div>
        <div class="d-flex text-center justify-content-center">
          <div>
            <p class="headline">
              {{ $t("success_messages." + configType + ".headline") }}
            </p>
            <p class="context">
              {{ $t("success_messages." + configType + ".context_1") }}
            </p>
            <p v-if="configType === 'booking_request'" class="context">
              {{ $t("success_messages." + configType + ".context_2") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cssVars from "css-vars-ponyfill";

export default {
  name: "SuccessMessage",
  props: {
    config: Object,
    configType: {
      default: "newsletter_request",
      type: String
    }
  },
  mounted() {
    const defaultCssSet = {
      "--general_font_color": "#494d50",
      "--general_font_family": "'Open Sans', sans-serif",
      "--general_background_color": "#FFFFFF"
    };
    cssVars({ variables: defaultCssSet });
    const vars = {};
    Object.keys(this.config.style).forEach((key) => {
      Object.keys(this.config.style[key]).forEach((prop) => {
        if (this.config.style?.[key]?.[prop] !== "") {
          vars["--" + key + "_" + prop] = this.config.style[key][prop];
        }
      });
    });
    cssVars({ variables: vars });
  }
};
</script>

<style scoped>
* {
  font-family: var(--general_font_family);
  color: var(--general_font_color);
}

.widget-body {
  margin: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 2px;
  max-width: 635px;
}

.widget-body .success-message-wrapper .headline {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 2.5rem;
}

.widget-body .success-message-wrapper .context {
  font-weight: normal;
}

.widget-body .success-message-wrapper .success-icon-wrapper {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
</style>
