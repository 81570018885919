export default class CustomField {
  dtoToModel = (dto) => {
    return dto.map((el, index) => {
      if (el.type === "checkbox_group") {
        el.value =
          el?.checkboxes?.map((checkbox) => {
            return {
              ...checkbox,
              value: false
            };
          }) || [];
      } else {
        el.value = "";
      }
      return {
        ...el,
        index: index
      };
    });
  };
  modelToDto = (model) => {
    return model.map((el) => {
      return {
        type: el.type,
        translations: el.translations,
        value: el.value
      };
    });
  };
}
