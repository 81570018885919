<template>
  <div class="widget-body" ref="widgetBody" v-if="ready">
    <section class="container" ref="required_data">
      <div class="row">
        <div class="col-12 mt-3" v-if="fieldProperty('name_prefix_slug').show">
          <label for="gender-selector" class="form-label"
            >{{ $t("form.gender_prefix.label")
            }}{{
              fieldProperty("name_prefix_slug").mandatory ? "* " : ""
            }}</label
          >
          <select
            class="form-select form-control"
            id="gender-selector"
            v-model="payload.customer.name_prefix_slug"
            @change="
              errors.indexOf('name_prefix_slug') > -1
                ? validate('name_prefix_slug')
                : null
            "
            @blur="validate('name_prefix_slug')"
            :class="{ 'is-invalid': errors.indexOf('name_prefix_slug') > -1 }"
          >
            <option value="">-</option>
            <option value="mr">{{ $t("form.gender_prefix.mr") }}</option>
            <option value="ms">{{ $t("form.gender_prefix.ms") }}</option>
            <option value="family">
              {{ $t("form.gender_prefix.family") }}
            </option>
          </select>
          <div class="invalid-feedback">
            {{
              $t("form.error.hint_missing", {
                field: $t("form.gender_prefix.label")
              })
            }}
          </div>
        </div>
        <div class="col-12 mt-3" v-if="fieldProperty('firstname').show">
          <label for="firstname-input" class="form-label"
            >{{ $t("form.firstname")
            }}{{ fieldProperty("firstname").mandatory ? "* " : "" }}</label
          >
          <input
            v-model="payload.customer.firstname"
            :class="{ 'is-invalid': errors.indexOf('firstname') > -1 }"
            class="form-control"
            id="firstname-input"
            @input="
              errors.indexOf('firstname') > -1 ? validate('firstname') : null
            "
            @blur="validate('firstname')"
          />
          <div class="invalid-feedback">
            {{ $t("form.error.hint_missing", { field: $t("form.firstname") }) }}
          </div>
        </div>
        <div class="col-12 mt-3" v-if="fieldProperty('lastname').show">
          <label for="surname-input" class="form-label"
            >{{ $t("form.lastname")
            }}{{ fieldProperty("lastname").mandatory ? "* " : "" }}</label
          >
          <input
            v-model="payload.customer.lastname"
            :class="{ 'is-invalid': errors.indexOf('lastname') > -1 }"
            class="form-control"
            id="surname-input"
            @input="
              errors.indexOf('lastname') > -1 ? validate('lastname') : null
            "
            @blur="validate('lastname')"
          />
          <div class="invalid-feedback">
            {{ $t("form.error.hint_missing", { field: $t("form.lastname") }) }}
          </div>
        </div>
        <div class="col-12 mt-3" v-if="fieldProperty('email').show">
          <label for="email-input" class="form-label"
            >{{ $t("form.email")
            }}{{ fieldProperty("email").mandatory ? "* " : "" }}</label
          >
          <input
            v-model="payload.customer.email"
            class="form-control"
            :class="{ 'is-invalid': errors.indexOf('email') > -1 }"
            type="email"
            id="email-input"
            @input="errors.indexOf('email') > -1 ? validate('email') : null"
            @blur="validate('email')"
          />
          <div class="invalid-feedback">
            {{ $t("form.error.hint_invalid", { field: $t("form.email") }) }}
          </div>
        </div>
      </div>
    </section>
    <section class="container">
      <div class="row">
        <div class="col-12 mt-3" v-if="fieldProperty('street').show">
          <label for="address-input" class="form-label"
            >{{ $t("form.street")
            }}{{ fieldProperty("street").mandatory ? "* " : "" }}</label
          >
          <input
            type="text"
            class="form-control"
            v-model="payload.customer.street"
            id="address-input"
            @input="errors.indexOf('street') > -1 ? validate('street') : null"
            @blur="validate('street')"
            :class="{ 'is-invalid': errors.indexOf('street') > -1 }"
          />
          <div class="invalid-feedback">
            {{ $t("form.error.hint_missing", { field: $t("form.street") }) }}
          </div>
        </div>
        <div class="col-4 mt-3" v-if="fieldProperty('zip').show">
          <label for="zip-input" class="form-label"
            >{{ $t("form.zip")
            }}{{ fieldProperty("zip").mandatory ? "* " : "" }}</label
          >
          <input
            type="text"
            class="form-control"
            id="zip-input"
            v-model="payload.customer.zip"
            @input="errors.indexOf('zip') > -1 ? validate('zip') : null"
            @blur="validate('zip')"
            :class="{ 'is-invalid': errors.indexOf('zip') > -1 }"
          />
          <div class="invalid-feedback">
            {{ $t("form.error.hint_missing", { field: $t("form.zip") }) }}
          </div>
        </div>
        <div class="col-8 mt-3" v-if="fieldProperty('city').show">
          <label for="city-input" class="form-label"
            >{{ $t("form.city")
            }}{{ fieldProperty("city").mandatory ? "* " : "" }}</label
          >
          <input
            type="text"
            class="form-control"
            v-model="payload.customer.city"
            id="city-input"
            @input="errors.indexOf('city') > -1 ? validate('city') : null"
            @blur="validate('city')"
            :class="{ 'is-invalid': errors.indexOf('city') > -1 }"
          />
          <div class="invalid-feedback">
            {{ $t("form.error.hint_missing", { field: $t("form.city") }) }}
          </div>
        </div>
        <div class="col-12 mt-3" v-if="fieldProperty('country_iso').show">
          <label for="country-input" class="form-label"
            >{{ $t("form.country_iso")
            }}{{ fieldProperty("country_iso").mandatory ? "* " : "" }}</label
          >
          <select
            id="country-input"
            v-model="payload.customer.country_iso"
            class="form-select form-control"
            @change="
              errors.indexOf('country_iso') > -1
                ? validate('country_iso')
                : null
            "
            @blur="validate('country_iso')"
            :class="{ 'is-invalid': errors.indexOf('country_iso') > -1 }"
          >
            <option
              :key="country.value"
              :value="country.value"
              v-for="country in countries"
            >
              {{ country.text }}
            </option>
          </select>
          <div class="invalid-feedback">
            {{
              $t("form.error.hint_missing", { field: $t("form.country_iso") })
            }}
          </div>
        </div>
        <div class="col-12 mt-3" v-if="fieldProperty('note').show">
          <label for="form-notes" class="form-label"
            >{{ $t("form.note")
            }}{{ fieldProperty("note").mandatory ? "* " : "" }}</label
          >
          <textarea
            class="form-control"
            id="form-notes"
            v-model="payload.customer.note"
            rows="4"
            @input="errors.indexOf('note') > -1 ? validate('note') : null"
            @blur="validate('note')"
            :class="{ 'is-invalid': errors.indexOf('note') > -1 }"
          ></textarea>
          <div class="invalid-feedback">
            {{ $t("form.error.hint_missing", { field: $t("form.note") }) }}
          </div>
        </div>
      </div>
    </section>
    <section class="container consents-wrapper">
      <div class="row">
        <div class="col-md-12 col-12 py-3">
          <div
            v-for="consent in consents"
            :key="`${consent.name}_input_wrapper`"
            class="form-check my-3"
          >
            <input
              v-model="payload.customer[consent.name]"
              :name="consent.name"
              :class="{ 'is-invalid': errors.indexOf(consent.name) > -1 }"
              :key="consent.name"
              @change="
                errors.indexOf(consent.name) > -1
                  ? validate(consent.name)
                  : null
              "
              @blur="validate(consent.name)"
              class="form-check-input"
              type="checkbox"
              :id="consent.name + '-checkmark'"
            />
            <div class="ms-2" v-if="!isPrivacyApp && consent.url">
              <div class="custom-check-label d-inline-block">
                <label
                  class="form-check-label"
                  :for="consent.name + '-checkmark'"
                >
                  <svg
                    v-if="consent.name == 'whatsapp_consent'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-whatsapp"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994
                                            14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592
                                            6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729
                                            0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z
                                        "
                    />
                  </svg>
                  <a class="show-as-link" :href="consent.url" target="_blank">{{
                    consent.label
                  }}</a>
                </label>
              </div>
            </div>
            <div class="ms-2" v-else>
              <div class="d-flex custom-check-label d-inline-block">
                <label
                  class="form-check-label"
                  :for="consent.name + '-checkmark'"
                >
                  <svg
                    v-if="consent.name == 'whatsapp_consent'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-whatsapp"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994
                                            14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592
                                            6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729
                                            0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z
                                        "
                    />
                  </svg>
                  <span
                    :style="
                      consent.name == 'whatsapp_consent'
                        ? 'margin-left: 8px;'
                        : ''
                    "
                    >{{ consent.label }}</span
                  >
                </label>
                <div
                  class="custom-check"
                  :class="{
                    checked:
                      consents_open[consent.name] ||
                      payload.customer[consent.name]
                  }"
                  @click="alertCustom(consent.name)"
                ></div>
              </div>
              <transition name="slide">
                <div
                  v-if="
                    (consent.name == 'whatsapp_consent' &&
                      payload.customer[consent.name]) ||
                    (consent.text && payload.customer[consent.name]) ||
                    consents_open[consent.name]
                  "
                >
                  <div
                    class="col-12 col-md-4 col-lg-3 pt-2"
                    v-if="
                      consent.name == 'whatsapp_consent' &&
                      (payload.customer[consent.name] ||
                        consents_open[consent.name])
                    "
                  >
                    <VueTelInput
                      :key="`whatsapp_input_${defaultCountry.key}`"
                      :default-country="defaultCountry.value"
                      id="whatsapp-input"
                      class="form-control"
                      mode="international"
                      :inputOptions="{
                        placeholder: '',
                        styleClasses: 'form-control'
                      }"
                      v-model="payload.customer.whatsapp_number"
                      @input="
                        errors.indexOf('whatsapp_number') > -1
                          ? validate('whatsapp_number')
                          : null
                      "
                      :class="{
                        'is-invalid': errors.indexOf('whatsapp_number') > -1
                      }"
                    />
                    <div class="invalid-feedback">
                      {{
                        $t("form.error.hint_invalid", {
                          field: $t("form.phone")
                        })
                      }}
                    </div>
                    <!--                                    <p v-if="consent.text" style="font-size: small;" class="mt-1 mb-0">-->
                    <!--                                        <span v-html="consent.text"></span>-->
                    <!--                                    </p>-->
                  </div>
                  <p
                    v-if="
                      payload.customer[consent.name] ||
                      consents_open[consent.name]
                    "
                    :role="
                      'privacy_text' in config &&
                      config.privacy_text &&
                      consent.name === 'privacy_consent'
                        ? 'button'
                        : ''
                    "
                    @click="
                      'privacy_text' in config &&
                        config.privacy_text &&
                        consent.name === 'privacy_consent' &&
                        openConsent(consent, config.privacy_text)
                    "
                    class="consent-description mt-2 mb-0"
                    v-html="consent.text"
                  />
                </div>
              </transition>
            </div>
            <div class="ms-2 invalid-feedback">
              {{ $t("form.error.consent_mandatory") }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container px-0 actions-wrapper">
      <div class="row">
        <div
          class="d-grid d-md-flex text-md-center py-3 justify-content-center"
        >
          <button
            :disabled="request.state"
            type="button"
            class="btn custom-button"
            @click="submitForm()"
          >
            <span
              v-if="request.state"
              class="spinner-border"
              role="status"
            ></span>
            {{ $t("form.subscribe_now") }}
          </button>
        </div>
      </div>
    </section>
    <transition name="fade">
      <div id="popup" v-if="popup.state" :class="popup.type">
        <h5 v-html="popup.title" v-if="popup.title"></h5>
        <p v-html="popup.message" v-if="popup.message"></p>
      </div>
    </transition>
    <ConsentDialog ref="consentDialog" />
  </div>
</template>

<script>
import { countryList } from "@/models/countryList";
import cssVars from "css-vars-ponyfill";
import ConsentDialog from "@/components/ConsentDialog.vue";
import * as EmailValidator from "email-validator";

export default {
  name: "NewsletterRequest",
  components: { ConsentDialog },
  props: {
    config: Object,
    settings: Object,
    isMobile: Boolean
  },
  data() {
    return {
      defaultCountry: {
        value: "",
        key: 1
      },
      consents: [],
      consents_open: {},
      ready: false,
      request: {
        state: false
      },
      popup: {
        state: false,
        type: "msg-success",
        message: ""
      },
      errors: [],
      countries: countryList
        .map((country) => ({
          value: country.alpha3,
          code: country.code,
          pinned: country.pinned,
          text:
            typeof country.translations.data[this.$i18n.locale] != "undefined"
              ? country.translations.data[this.$i18n.locale].name
              : country.translations.data.en.name
        }))
        .sort(function (a, b) {
          if (a.pinned < b.pinned) return +1;
          if (a.pinned > b.pinned) return -1;
          if (a.text > b.text) return +1;
          if (a.text < b.text) return -1;
          return 0;
        })
        .map((country) => ({
          value: country.value,
          text: country.text
        })),
      payload: {
        customer: {
          language: this.$i18n.locale,
          name_prefix_slug: "",
          firstname: "",
          lastname: "",
          street: "",
          city: "",
          zip: "",
          country_iso: "",
          email: "",
          privacy_consent: true
        }
      }
    };
  },
  watch: {
    state: {
      handler: function (val) {
        this.$emit("widget-event", "onStateChanged", val);
      },
      deep: true
    }
  },
  mounted() {
    let head = document.getElementsByTagName("head")[0];
    let link = document.createElement("link");
    link.id = "googleFont";
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "https://fonts.googleapis.com/css?family=Open+Sans:400,500,700";
    link.media = "all";
    head.appendChild(link);
    let defaultCssSet = {
      "--cta_background_color": "#494d50",
      "--cta_font_color": "#ffffff",
      "--cta_font_family": "Arial, Verdana, sans-serif",
      "--general_font_color": "#494d50",
      "--general_font_family": "'Open Sans', sans-serif",
      "--general_background_color": "#FFFFFF"
    };
    cssVars({ variables: defaultCssSet });
    let vars = {};
    Object.keys(this.config.style).forEach((key) => {
      if (this.config.style?.[key]) {
        Object.keys(this.config.style[key]).forEach((prop) => {
          if (
            this.config.style[key][prop] &&
            this.config.style[key][prop] != ""
          ) {
            vars["--" + key + "_" + prop] = this.config.style[key][prop];
          }
        });
      }
    });
    cssVars({ variables: vars });
    if (this.config.style_css && this.config.style_css != "") {
      let styleEl = document.createElement("style");
      styleEl.innerHTML = this.config.style_css.replace(/(<([^>]+)>)/gi, "");
      document
        .querySelector("travel-suite-widget")
        .shadowRoot.appendChild(styleEl);
    }
    if (
      "recaptcha" in this.config.config &&
      this.config.config.recaptcha.enabled
    ) {
      if (this.config.config.recaptcha.public_key != "") {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.src =
          "https://www.google.com/recaptcha/api.js?render=" +
          this.config.config.recaptcha.public_key;
        document.head.appendChild(s);
      }
    }
    let filteredProps = ["lang", "uuid"];
    let objectArray = [];
    let expression = /^p([0-9]+)_([a-z_]*)$/gim;
    Object.keys(this.settings).forEach((property) => {
      if (filteredProps.indexOf(property) === -1) {
        let caughtArray = expression.exec(property);
        if (!caughtArray) {
          if (property === "to_date" || property === "from_date") {
            if (this.isValidDate(this.settings[property])) {
              this.payload.customer[property] = this.settings[property];
            } else {
            }
          } else {
            this.payload.customer[property] = this.settings[property];
          }
        } else {
          if (typeof objectArray[caughtArray[1]] == "undefined")
            objectArray[caughtArray[1]] = {};
          objectArray[caughtArray[1]][caughtArray[2]] = this.settings[property];
        }
      }
    });
    this.consents =
      this.config?.consent_requests?.data?.filter(
        (el) => el.name !== "privacy_consent"
      ) || [];
    this.consents.forEach((consent) => {
      this.consents_open[consent.name] = false;
    });
    this.setDefaultValidations();
    this.setAutoDefaultCountry();
    this.ready = true;
  },
  computed: {
    state() {
      return {
        language: this.payload.customer.language,
        name_prefix_slug: this.payload.customer.name_prefix_slug,
        firstname: this.payload.customer.firstname,
        lastname: this.payload.customer.lastname,
        street: this.payload.customer.street,
        city: this.payload.customer.city,
        zip: this.payload.customer.zip,
        country_iso: this.payload.customer.country_iso,
        email: this.payload.customer.email
      };
    },
    isPrivacyApp() {
      return (
        "privacy_app" in this.config.config && this.config.config.privacy_app
      );
    }
  },
  methods: {
    async setAutoDefaultCountry() {
      try {
        const response = await this.$http.get("https://ip2c.org/s");
        if (response?.status === 200) {
          this.defaultCountry.value = response?.data?.split(";")?.[1] || "IT";
        }
      } catch (e) {
        this.defaultCountry.value = "IT";
      } finally {
        this.defaultCountry.key++;
      }
    },
    setDefaultValidations() {
      this.errors = [];
      if (
        this.isPrivacyApp &&
        this.config?.consent_requests?.data?.find(
          (el) => el.name === "marketing_consent"
        )
      ) {
        this.config.config.fields["marketing_consent"] = {
          show: true,
          mandatory: true
        };
      }
      if (!this.isPrivacyApp) {
        this.config.config.fields["newsletter_consent"] = {
          show: true,
          mandatory: true
        };
      }
    },
    openConsent(consent) {
      this.$refs.consentDialog?.openConsent(consent);
    },
    alertCustom(name) {
      this.consents_open[name] = !this.consents_open[name];
      this.$forceUpdate();
    },
    fieldProperty(field) {
      if (Object.keys(this.config.config.fields).indexOf(field) > -1) {
        return this.config.config.fields[field];
      } else {
        return {};
      }
    },
    showPopup(params) {
      this.popup.title = params.title || null;
      this.popup.message = params.message;
      this.popup.type = params.type;
      this.popup.state = true;
      setTimeout(() => {
        this.popup.state = false;
      }, 5000);
    },
    submitForm() {
      this.validate();
      if (this.errors.length == 0) {
        if (
          "recaptcha" in this.config.config &&
          this.config.config.recaptcha.enabled
        ) {
          let vm = this;
          grecaptcha.ready(function () {
            // eslint-disable-next-line no-undef
            grecaptcha
              .execute(vm.config.config.recaptcha.public_key, {
                action: "submit"
              })
              .then(function (token) {
                vm.submitData(token);
              });
          });
        } else {
          this.submitData();
        }
        this.$forceUpdate();
      } else {
        this.$nextTick(() => {
          const el = this.$refs.widgetBody.querySelector(".is-invalid");
          el
            ? el.scrollIntoView({ block: "center", behavior: "smooth" })
            : null;
        });
      }
    },
    submitData(token = null) {
      this.request.state = true;
      let payload = JSON.parse(JSON.stringify(this.payload));
      if (token) payload.recaptcha_token = token;
      this.consents.forEach((consent) => {
        if (!(consent.name in payload.customer))
          payload.customer[consent.name] = false;
      });
      this.$http
        .post("widget/" + this.config.uuid + "/newsletterrequest", payload)
        .then(() => {
          this.$emit("success-event");
          this.$emit("widget-event", "onSubscribeSuccess", this.state);
          this.$emit("datalayer-event", {
            event: "generate_lead",
            affiliation: "Yanovis Enquiry Widget"
          });
          this.request.state = !this.request.state;
        })
        .catch((error) => {
          let errorTitle = "",
            errorMsg = this.$t("default.error_message");
          this.showPopup({
            title: errorTitle,
            message: errorMsg || null,
            type: "msg-error"
          });
          this.request.state = !this.request.state;
        });
    },
    validate(fieldInput = "all") {
      let input =
        fieldInput == "all"
          ? Object.keys(this.config.config.fields)
          : [fieldInput];
      input.forEach((field) => {
        let control;
        if (this.config.config.fields?.[field]?.mandatory) {
          switch (field) {
            case "marketing_consent": {
              control =
                Object.keys(this.payload.customer).indexOf(
                  "marketing_consent"
                ) == -1 || !this.payload.customer.marketing_consent;
              break;
            }
            case "newsletter_consent": {
              control =
                Object.keys(this.payload.customer).indexOf(
                  "newsletter_consent"
                ) == -1 || !this.payload.customer.newsletter_consent;
              break;
            }
            default: {
              control = this.payload.customer[field] == "";
            }
          }
        }
        switch (field) {
          case "email": {
            if (
              !this.payload.customer.email ||
              !EmailValidator.validate(this.payload.customer.email)
            )
              control = true;
            break;
          }
        }
        if (control) {
          this.errors.indexOf(field) == -1 && this.errors.push(field);
        } else if (fieldInput != "all") {
          const index = this.errors.indexOf(field);
          index >= 0 && this.errors.splice(index, 1);
        }
      });
      this.$forceUpdate();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
label {
  cursor: pointer;
}

.widget-body {
  margin: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 2px;
}

* {
  font-family: var(--general_font_family);
  color: var(--general_font_color);
}

.form-control {
  padding: 0.5rem !important;
}

.grecaptcha-badge {
  display: none !important;
}

.main {
  max-width: 655px;
}
</style>
