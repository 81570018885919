export default {
  datepicker: {
    night: "Nuit",
    nights: "Nuits",
    week: "Semaine",
    weeks: "Semaines",
    "day-names": ["Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"],
    "check-in": "Arrivée",
    "check-out": "Départ",
    "month-names": [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre"
    ],
    tooltip: {
      halfDayCheckIn: "Check-in possible",
      halfDayCheckOut: "Check-out possible",
      saturdayToSaturday: "Seulement de samedi à samedi",
      sundayToSunday: "Seulement de dimanche à dimanche",
      minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum"
    },
    format: "DD/MM/YYYY",
    format_placehoder: "JJ/MM/AAAA"
  },
  form: {
    check_in_out: "{check_in} et {check_out}",
    age_child_x: "Âge de l'enfant {x}",
    persons_x: "{min}-{max} Personnes",
    mealplan: "Repas",
    board: {
      any: "Any board"
    },
    adult_pl: "Adulte | Adultes",
    child_pl: "Enfant | Enfants",
    children: {
      age_x: "<1|{count} Année |{count} Années"
    },
    offer: {
      any: "N'importe quel Offre",
      offer_pl: "Offre | Offres"
    },
    room: {
      any: "Chaque chambre",
      room_pl: "Chambre | Chambres",
      add: "Ajouter chambre",
      remove: "Enlever chambre"
    },
    gender: {
      label: "Salutation",
      m: "Monsieur",
      f: "Madame"
    },
    gender_prefix: {
      label: "Salutation",
      mr: "Mr",
      ms: "Ms",
      family: "Family",
      doctor: "Dr",
      doctor_ms: "Dr",
      ms_mr: "Sir or Madam",
      company: "Société"
    },
    companyname: "Nom de la société ",
    firstname: "Prénom",
    lastname: "Nom de famille",
    email: "E-Mail",
    phone: "Téléphone",
    street: "Rue",
    zip: "Postcode",
    city: "Localité",
    country_iso: "Pays",
    note: "Note",
    error: {
      hint_missing: "Complétez s'il vous plait '{field}'",
      hint_invalid: "S'il vous plaît, complétez correctement '{field}'",
      consent_mandatory: "Complétez sil vous plaît le champ obligatoire"
    },
    submit: "Envoyer la demande",
    subscribe_now: "S'ABONNER MAINTENANT",
    messages: {
      request_sent: "La demande a été envoyée avec succès",
      error: "Une erreur est survenue"
    },
    consent_default_title: "Consentement marketing"
  },
  success_messages: {
    newsletter_request: {
      headline: "Inscription à la newsletter couronnée de succès !",
      context_1:
        "Merci de votre inscription à notre newsletter. Vous allez recevoir un e-mail sous peu. S'il vous plaît, confirmez l'inscription en cliquant sur le lien."
    },
    booking_request: {
      headline: "Demande envoyée avec succès !",
      context_1: "Nous vous remercions de votre demande et de votre intérêt.",
      context_2:
        "Vous recevrez prochainement notre offre individuelle pour la période de votre choix."
    }
  },
  default: {
    error_message:
      "Une erreur inattendue est apparue.\n Réessayez plus tard, s'il vous plaît." // this is Italian, needs to change
  }
};
