export default {
  datepicker: {
    night: "Night",
    nights: "Nights",
    week: "Week",
    weeks: "Weeks",
    "day-names": ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
    "check-in": "Arrival",
    "check-out": "Departure",
    "month-names": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    tooltip: {
      halfDayCheckIn: "Available Check-In",
      halfDayCheckOut: "Available Check-Out",
      saturdayToSaturday: "Only Saturday to Saturday",
      sundayToSunday: "Only Sunday to Sunday",
      minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum."
    },
    format: "DD.MM.YYYY",
    format_placehoder: "DD.MM.YYYY"
  },
  form: {
    check_in_out: "{check_in} and {check_out}",
    age_child_x: "Age of child {x}",
    persons_x: "{min}-{max} Persons",
    mealplan: "Board",
    board: {
      any: "Any board"
    },
    adult_pl: "Adult | Adults",
    child_pl: "Child | Children",
    children: {
      age_x: "<1|{count} Year |{count} Years"
    },
    offer: {
      any: "Any offer",
      offer_pl: "Offer | Offers"
    },
    room: {
      any: "Any room",
      room_pl: "Room | Rooms",
      add: "Add room",
      remove: "Remove room"
    },
    gender: {
      label: "Title",
      m: "Mr.",
      f: "Mrs."
    },
    gender_prefix: {
      label: "Salutation",
      mr: "Mr",
      ms: "Ms",
      family: "Family",
      doctor: "Dr",
      doctor_ms: "Dr",
      ms_mr: "Sir or Madam",
      company: "Business"
    },
    companyname: "Business name",
    firstname: "Firstname",
    lastname: "Lastname",
    email: "E-mail",
    phone: "Phone",
    street: "Street",
    zip: "ZIP",
    city: "City",
    country_iso: "Country",
    note: "Notes",
    error: {
      hint_missing: "Please enter '{field}'",
      hint_invalid: "Please enter valid '{field}'",
      consent_mandatory: "Please enter the mandatory field"
    },
    submit: "Send request",
    subscribe_now: "SUBSCRIBE NOW",
    messages: {
      request_sent: "The request was sent successfully",
      error: "An error has occured"
    },
    consent_default_title: "Marketing consent"
  },
  success_messages: {
    newsletter_request: {
      headline: "Newsletter subscription successful!",
      context_1:
        "Thank you for subscribing to our newsletter. You will shortly receive an e-mail. Please confirm your registration by clicking on the link."
    },
    booking_request: {
      headline: "Request successfully sent!",
      context_1: "Thank you for your request!",
      context_2:
        "Soon you will receive our individual offer for your desired period."
    }
  },
  default: {
    error_message: "An unexpected error has occurred.\n Please try again later."
  }
};
