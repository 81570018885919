import de from "./de/messages";
import en from "./en/messages";
import fr from "./fr/messages";
import it from "./it/messages";
// import es from './es/messages';
// import pt from './pt/messages';

const messages = {
  de,
  en,
  fr,
  it
  // pt,
  // es,
};

export default messages;
