export const countryList = [
  {
    id: 250,
    pinned: 0,
    code: "AX",
    alpha3: "ALA",
    numeric: "248",
    translations: {
      data: {
        en: {
          name: "Åland Islands"
        }
      }
    }
  },
  {
    id: 251,
    pinned: 0,
    code: "BQ",
    alpha3: "BES",
    numeric: "535",
    translations: {
      data: {
        en: {
          name: "Bonaire"
        }
      }
    }
  },
  {
    id: 1,
    pinned: 0,
    code: "AF",
    alpha3: "AFG",
    numeric: "004",
    translations: {
      data: {
        de: {
          name: "Afghanistan"
        },
        en: {
          name: "Afghanistan"
        },
        it: {
          name: "Afghanistan"
        }
      }
    }
  },
  {
    id: 2,
    pinned: 0,
    code: "EG",
    alpha3: "EGY",
    numeric: "818",
    translations: {
      data: {
        de: {
          name: "Ägypten"
        },
        en: {
          name: "Egypt"
        },
        it: {
          name: "Egitto"
        }
      }
    }
  },
  {
    id: 3,
    pinned: 0,
    code: "AL",
    alpha3: "ALB",
    numeric: "008",
    translations: {
      data: {
        de: {
          name: "Albanien"
        },
        en: {
          name: "Albania"
        },
        it: {
          name: "Albania"
        }
      }
    }
  },
  {
    id: 4,
    pinned: 0,
    code: "DZ",
    alpha3: "DZA",
    numeric: "012",
    translations: {
      data: {
        de: {
          name: "Algerien"
        },
        en: {
          name: "Algeria"
        },
        it: {
          name: "Algeria"
        }
      }
    }
  },
  {
    id: 6,
    pinned: 0,
    code: "AS",
    alpha3: "ASM",
    numeric: "016",
    translations: {
      data: {
        de: {
          name: "Amerikanisch-Samoa"
        },
        en: {
          name: "American Samoa"
        },
        it: {
          name: "Samoa americane"
        }
      }
    }
  },
  {
    id: 5,
    pinned: 0,
    code: "VI",
    alpha3: "VIR",
    numeric: "850",
    translations: {
      data: {
        de: {
          name: "Amerikanische Jungferninseln"
        },
        en: {
          name: "Virgin Islands (U.S.)"
        },
        it: {
          name: "Isole Vergini Americane"
        }
      }
    }
  },
  {
    id: 7,
    pinned: 0,
    code: "AD",
    alpha3: "AND",
    numeric: "020",
    translations: {
      data: {
        de: {
          name: "Andorra"
        },
        en: {
          name: "Andorra"
        },
        it: {
          name: "Andorra"
        }
      }
    }
  },
  {
    id: 8,
    pinned: 0,
    code: "AO",
    alpha3: "AGO",
    numeric: "024",
    translations: {
      data: {
        de: {
          name: "Angola"
        },
        en: {
          name: "Angola"
        },
        it: {
          name: "Angola"
        }
      }
    }
  },
  {
    id: 9,
    pinned: 0,
    code: "AI",
    alpha3: "AIA",
    numeric: "660",
    translations: {
      data: {
        de: {
          name: "Anguilla"
        },
        en: {
          name: "Anguilla"
        },
        it: {
          name: "Anguilla"
        }
      }
    }
  },
  {
    id: 10,
    pinned: 0,
    code: "AQ",
    alpha3: "ATA",
    numeric: "010",
    translations: {
      data: {
        de: {
          name: "Antarktis"
        },
        en: {
          name: "Antarctica"
        },
        it: {
          name: "Antarktika"
        }
      }
    }
  },
  {
    id: 11,
    pinned: 0,
    code: "AG",
    alpha3: "ATG",
    numeric: "028",
    translations: {
      data: {
        de: {
          name: "Antigua und Barbuda"
        },
        en: {
          name: "Antigua and Barbuda"
        },
        it: {
          name: "Antigua e Barbuda"
        }
      }
    }
  },
  {
    id: 12,
    pinned: 0,
    code: "GQ",
    alpha3: "GNQ",
    numeric: "226",
    translations: {
      data: {
        de: {
          name: "Äquatorialguinea"
        },
        en: {
          name: "Equatorial Guinea"
        },
        it: {
          name: "Guinea Equatoriale"
        }
      }
    }
  },
  {
    id: 13,
    pinned: 0,
    code: "AR",
    alpha3: "ARG",
    numeric: "032",
    translations: {
      data: {
        de: {
          name: "Argentinien"
        },
        en: {
          name: "Argentina"
        },
        it: {
          name: "Argentina"
        }
      }
    }
  },
  {
    id: 14,
    pinned: 0,
    code: "AM",
    alpha3: "ARM",
    numeric: "051",
    translations: {
      data: {
        de: {
          name: "Armenien"
        },
        en: {
          name: "Armenia"
        },
        it: {
          name: "Armenia"
        }
      }
    }
  },
  {
    id: 15,
    pinned: 0,
    code: "AW",
    alpha3: "ABW",
    numeric: "533",
    translations: {
      data: {
        de: {
          name: "Aruba"
        },
        en: {
          name: "Aruba"
        },
        it: {
          name: "Aruba"
        }
      }
    }
  },
  {
    id: 16,
    pinned: 0,
    code: "AZ",
    alpha3: "AZE",
    numeric: "031",
    translations: {
      data: {
        de: {
          name: "Aserbaidschan"
        },
        en: {
          name: "Azerbaijan"
        },
        it: {
          name: "Azerbaigian"
        }
      }
    }
  },
  {
    id: 17,
    pinned: 0,
    code: "ET",
    alpha3: "ETH",
    numeric: "231",
    translations: {
      data: {
        de: {
          name: "Äthiopien"
        },
        en: {
          name: "Ethiopia"
        },
        it: {
          name: "Etiopia"
        }
      }
    }
  },
  {
    id: 18,
    pinned: 0,
    code: "AU",
    alpha3: "AUS",
    numeric: "036",
    translations: {
      data: {
        de: {
          name: "Australien"
        },
        en: {
          name: "Australia"
        },
        it: {
          name: "Australia"
        }
      }
    }
  },
  {
    id: 19,
    pinned: 0,
    code: "BS",
    alpha3: "BHS",
    numeric: "044",
    translations: {
      data: {
        de: {
          name: "Bahamas"
        },
        en: {
          name: "Bahamas"
        },
        it: {
          name: "Bahamas"
        }
      }
    }
  },
  {
    id: 20,
    pinned: 0,
    code: "BH",
    alpha3: "BHR",
    numeric: "048",
    translations: {
      data: {
        de: {
          name: "Bahrain"
        },
        en: {
          name: "Bahrain"
        },
        it: {
          name: "Bahrein"
        }
      }
    }
  },
  {
    id: 21,
    pinned: 0,
    code: "BD",
    alpha3: "BGD",
    numeric: "050",
    translations: {
      data: {
        de: {
          name: "Bangladesch"
        },
        en: {
          name: "Bangladesh"
        },
        it: {
          name: "Bangladesh"
        }
      }
    }
  },
  {
    id: 22,
    pinned: 0,
    code: "BB",
    alpha3: "BRB",
    numeric: "052",
    translations: {
      data: {
        de: {
          name: "Barbados"
        },
        en: {
          name: "Barbados"
        },
        it: {
          name: "Barbados"
        }
      }
    }
  },
  {
    id: 23,
    pinned: 0,
    code: "BY",
    alpha3: "BLR",
    numeric: "112",
    translations: {
      data: {
        de: {
          name: "Belarus"
        },
        en: {
          name: "Belarus"
        },
        it: {
          name: "Bielorussia"
        }
      }
    }
  },
  {
    id: 24,
    pinned: 0,
    code: "BE",
    alpha3: "BEL",
    numeric: "056",
    translations: {
      data: {
        de: {
          name: "Belgien"
        },
        en: {
          name: "Belgium"
        },
        it: {
          name: "Belgio"
        }
      }
    }
  },
  {
    id: 25,
    pinned: 0,
    code: "BZ",
    alpha3: "BLZ",
    numeric: "084",
    translations: {
      data: {
        de: {
          name: "Belize"
        },
        en: {
          name: "Belize"
        },
        it: {
          name: "Belize"
        }
      }
    }
  },
  {
    id: 26,
    pinned: 0,
    code: "BJ",
    alpha3: "BEN",
    numeric: "204",
    translations: {
      data: {
        de: {
          name: "Benin"
        },
        en: {
          name: "Benin"
        },
        it: {
          name: "Benin"
        }
      }
    }
  },
  {
    id: 27,
    pinned: 0,
    code: "BM",
    alpha3: "BMU",
    numeric: "060",
    translations: {
      data: {
        de: {
          name: "Bermuda"
        },
        en: {
          name: "Bermuda"
        },
        it: {
          name: "Bermuda"
        }
      }
    }
  },
  {
    id: 28,
    pinned: 0,
    code: "BT",
    alpha3: "BTN",
    numeric: "064",
    translations: {
      data: {
        de: {
          name: "Bhutan"
        },
        en: {
          name: "Bhutan"
        },
        it: {
          name: "Bhutan"
        }
      }
    }
  },
  {
    id: 29,
    pinned: 0,
    code: "BO",
    alpha3: "BOL",
    numeric: "068",
    translations: {
      data: {
        de: {
          name: "Bolivien"
        },
        en: {
          name: "Bolivia (Plurinational State of Bolivia)"
        },
        it: {
          name: "Bolivia"
        }
      }
    }
  },
  {
    id: 30,
    pinned: 0,
    code: "BA",
    alpha3: "BIH",
    numeric: "070",
    translations: {
      data: {
        de: {
          name: "Bosnien und Herzegowina"
        },
        en: {
          name: "Bosnia and Herzegovina"
        },
        it: {
          name: "Bosnia Erzegovina"
        }
      }
    }
  },
  {
    id: 31,
    pinned: 0,
    code: "BW",
    alpha3: "BWA",
    numeric: "072",
    translations: {
      data: {
        de: {
          name: "Botswana"
        },
        en: {
          name: "Botswana"
        },
        it: {
          name: "Botswana"
        }
      }
    }
  },
  {
    id: 32,
    pinned: 0,
    code: "BV",
    alpha3: "BVT",
    numeric: "074",
    translations: {
      data: {
        de: {
          name: "Bouvetinsel"
        },
        en: {
          name: "Bouvet Island"
        },
        it: {
          name: "Isola Bouvet"
        }
      }
    }
  },
  {
    id: 33,
    pinned: 0,
    code: "BR",
    alpha3: "BRA",
    numeric: "076",
    translations: {
      data: {
        de: {
          name: "Brasilien"
        },
        en: {
          name: "Brazil"
        },
        it: {
          name: "Brasile"
        }
      }
    }
  },
  {
    id: 34,
    pinned: 0,
    code: "VG",
    alpha3: "VGB",
    numeric: "092",
    translations: {
      data: {
        de: {
          name: "Britische Jungferninseln"
        },
        en: {
          name: "British Virgin Islands"
        },
        it: {
          name: "Isole Vergini Britanniche"
        }
      }
    }
  },
  {
    id: 35,
    pinned: 0,
    code: "IO",
    alpha3: "IOT",
    numeric: "086",
    translations: {
      data: {
        de: {
          name: "Britisches Territorium im Indischen Ozean"
        },
        en: {
          name: "British Indian Ocean Territory"
        },
        it: {
          name: "Territorio britannico dell'Oceano Indiano"
        }
      }
    }
  },
  {
    id: 36,
    pinned: 0,
    code: "BN",
    alpha3: "BRN",
    numeric: "096",
    translations: {
      data: {
        de: {
          name: "Brunei"
        },
        en: {
          name: "Brunei Darussalam"
        },
        it: {
          name: "Brunei"
        }
      }
    }
  },
  {
    id: 37,
    pinned: 0,
    code: "BG",
    alpha3: "BGR",
    numeric: "100",
    translations: {
      data: {
        de: {
          name: "Bulgarien"
        },
        en: {
          name: "Bulgaria"
        },
        it: {
          name: "Bulgaria"
        }
      }
    }
  },
  {
    id: 38,
    pinned: 0,
    code: "BF",
    alpha3: "BFA",
    numeric: "854",
    translations: {
      data: {
        de: {
          name: "Burkina Faso"
        },
        en: {
          name: "Burkina Faso"
        },
        it: {
          name: "Burkina Faso"
        }
      }
    }
  },
  {
    id: 39,
    pinned: 0,
    code: "MM",
    alpha3: "MMR",
    numeric: "104",
    translations: {
      data: {
        de: {
          name: "Myanmar"
        },
        en: {
          name: "Myanmar"
        },
        it: {
          name: "Myanmar"
        }
      }
    }
  },
  {
    id: 40,
    pinned: 0,
    code: "BI",
    alpha3: "BDI",
    numeric: "108",
    translations: {
      data: {
        de: {
          name: "Burundi"
        },
        en: {
          name: "Burundi"
        },
        it: {
          name: "Burundi"
        }
      }
    }
  },
  {
    id: 41,
    pinned: 0,
    code: "CL",
    alpha3: "CHL",
    numeric: "152",
    translations: {
      data: {
        de: {
          name: "Chile"
        },
        en: {
          name: "Chile"
        },
        it: {
          name: "Cile"
        }
      }
    }
  },
  {
    id: 42,
    pinned: 0,
    code: "CN",
    alpha3: "CHN",
    numeric: "156",
    translations: {
      data: {
        de: {
          name: "China"
        },
        en: {
          name: "China"
        },
        it: {
          name: "China"
        }
      }
    }
  },
  {
    id: 43,
    pinned: 0,
    code: "CK",
    alpha3: "COK",
    numeric: "184",
    translations: {
      data: {
        de: {
          name: "Cookinseln"
        },
        en: {
          name: "Cook Islands"
        },
        it: {
          name: " Isole Cook"
        }
      }
    }
  },
  {
    id: 44,
    pinned: 0,
    code: "CR",
    alpha3: "CRI",
    numeric: "188",
    translations: {
      data: {
        de: {
          name: "Costa Rica"
        },
        en: {
          name: "Costa Rica"
        },
        it: {
          name: "Costa Rica"
        }
      }
    }
  },
  {
    id: 45,
    pinned: 0,
    code: "CI",
    alpha3: "CIV",
    numeric: "384",
    translations: {
      data: {
        de: {
          name: "Elfenbeinküste"
        },
        en: {
          name: "Côte d'Ivoire"
        },
        it: {
          name: "Costa d'Avorioe"
        }
      }
    }
  },
  {
    id: 46,
    pinned: 0,
    code: "CW",
    alpha3: "CUW",
    numeric: "531",
    translations: {
      data: {
        de: {
          name: "Curacao"
        },
        en: {
          name: "Curaçao"
        },
        it: {
          name: "Curacao"
        }
      }
    }
  },
  {
    id: 47,
    pinned: 0,
    code: "DK",
    alpha3: "DNK",
    numeric: "208",
    translations: {
      data: {
        de: {
          name: "Dänemark"
        },
        en: {
          name: "Denmark"
        },
        it: {
          name: "Danimarca"
        }
      }
    }
  },
  {
    id: 48,
    pinned: 0,
    code: "CD",
    alpha3: "COD",
    numeric: "180",
    translations: {
      data: {
        de: {
          name: "Demokratische Republik Kongo"
        },
        en: {
          name: "Democratic Republic of the Congo "
        },
        it: {
          name: "Repubblica Democratica del Congo"
        }
      }
    }
  },
  {
    id: 49,
    pinned: 1,
    code: "DE",
    alpha3: "DEU",
    numeric: "276",
    translations: {
      data: {
        de: {
          name: "Deutschland"
        },
        en: {
          name: "Germany"
        },
        it: {
          name: "Germania"
        }
      }
    }
  },
  {
    id: 50,
    pinned: 0,
    code: "DM",
    alpha3: "DMA",
    numeric: "212",
    translations: {
      data: {
        de: {
          name: "Dominica"
        },
        en: {
          name: "Dominica"
        },
        it: {
          name: "Dominica"
        }
      }
    }
  },
  {
    id: 51,
    pinned: 0,
    code: "DO",
    alpha3: "DOM",
    numeric: "214",
    translations: {
      data: {
        de: {
          name: "Dominikanische Republik"
        },
        en: {
          name: "Dominican Republic"
        },
        it: {
          name: "Repubblica Dominicana"
        }
      }
    }
  },
  {
    id: 52,
    pinned: 0,
    code: "DJ",
    alpha3: "DJI",
    numeric: "262",
    translations: {
      data: {
        de: {
          name: "Dschibuti"
        },
        en: {
          name: "Djibouti"
        },
        it: {
          name: "Gibuti"
        }
      }
    }
  },
  {
    id: 53,
    pinned: 0,
    code: "EC",
    alpha3: "ECU",
    numeric: "218",
    translations: {
      data: {
        de: {
          name: "Ecuador"
        },
        en: {
          name: "Ecuador"
        },
        it: {
          name: "Ecuador"
        }
      }
    }
  },
  {
    id: 54,
    pinned: 0,
    code: "SV",
    alpha3: "SLV",
    numeric: "222",
    translations: {
      data: {
        de: {
          name: "El Salvador"
        },
        en: {
          name: "El Salvador"
        },
        it: {
          name: "El Salvador"
        }
      }
    }
  },
  {
    id: 55,
    pinned: 0,
    code: "ER",
    alpha3: "ERI",
    numeric: "232",
    translations: {
      data: {
        de: {
          name: "Eritrea"
        },
        en: {
          name: "Eritrea"
        },
        it: {
          name: "Eritrea"
        }
      }
    }
  },
  {
    id: 56,
    pinned: 0,
    code: "EE",
    alpha3: "EST",
    numeric: "233",
    translations: {
      data: {
        de: {
          name: "Estland"
        },
        en: {
          name: "Estonia"
        },
        it: {
          name: "Estonia"
        }
      }
    }
  },
  {
    id: 57,
    pinned: 0,
    code: "FK",
    alpha3: "FLK",
    numeric: "238",
    translations: {
      data: {
        de: {
          name: "Falklandinseln (Malwinen)"
        },
        en: {
          name: "Falkland Islands (Malvinas)"
        },
        it: {
          name: "Isole Falkland (Malvine)"
        }
      }
    }
  },
  {
    id: 58,
    pinned: 0,
    code: "FO",
    alpha3: "FRO",
    numeric: "234",
    translations: {
      data: {
        de: {
          name: "Färöer"
        },
        en: {
          name: "Faroe Islands"
        },
        it: {
          name: "Isole Faringe"
        }
      }
    }
  },
  {
    id: 59,
    pinned: 0,
    code: "FJ",
    alpha3: "FJI",
    numeric: "242",
    translations: {
      data: {
        de: {
          name: "Fidschi"
        },
        en: {
          name: "Fiji"
        },
        it: {
          name: "Figi"
        }
      }
    }
  },
  {
    id: 60,
    pinned: 0,
    code: "FI",
    alpha3: "FIN",
    numeric: "246",
    translations: {
      data: {
        de: {
          name: "Finnland"
        },
        en: {
          name: "Finland"
        },
        it: {
          name: "Finnland"
        }
      }
    }
  },
  {
    id: 61,
    pinned: 1,
    code: "FR",
    alpha3: "FRA",
    numeric: "250",
    translations: {
      data: {
        de: {
          name: "Frankreich"
        },
        en: {
          name: "France"
        },
        it: {
          name: "Francia"
        }
      }
    }
  },
  {
    id: 64,
    pinned: 0,
    code: "GF",
    alpha3: "GUF",
    numeric: "254",
    translations: {
      data: {
        de: {
          name: "Französisch-Guyana"
        },
        en: {
          name: "French Guiana"
        },
        it: {
          name: "Guyana francese"
        }
      }
    }
  },
  {
    id: 65,
    pinned: 0,
    code: "PF",
    alpha3: "PYF",
    numeric: "258",
    translations: {
      data: {
        de: {
          name: "Französisch-Polynesien"
        },
        en: {
          name: "French Polynesia"
        },
        it: {
          name: "Polinesia francese"
        }
      }
    }
  },
  {
    id: 62,
    pinned: 0,
    code: "TF",
    alpha3: "ATF",
    numeric: "260",
    translations: {
      data: {
        de: {
          name: "Französische Süd- und Antarktisgebiete"
        },
        en: {
          name: "French Southern Territories"
        },
        it: {
          name: "Terre Australi e Antartiche Francesi"
        }
      }
    }
  },
  // {
  //     "id": 63,
  //     "pinned": 0,
  //     "code": "FX",
  //     "alpha3": "",
  //     "numeric": "",
  //     "translations": {
  //         "data": {
  //             "de": {
  //                 "name": "Französisches Mutterland"
  //             },
  //             "en": {
  //                 "name": "Französisches Mutterland"
  //             },
  //             "it": {
  //                 "name": "Französisches Mutterland"
  //             }
  //         }
  //     }
  // },
  {
    id: 66,
    pinned: 0,
    code: "GA",
    alpha3: "GAB",
    numeric: "266",
    translations: {
      data: {
        de: {
          name: "Gabun"
        },
        en: {
          name: "Gabon"
        },
        it: {
          name: "Gabon"
        }
      }
    }
  },
  {
    id: 67,
    pinned: 0,
    code: "GM",
    alpha3: "GMB",
    numeric: "270",
    translations: {
      data: {
        de: {
          name: "Gambia"
        },
        en: {
          name: "Gambia"
        },
        it: {
          name: "Gambia"
        }
      }
    }
  },
  {
    id: 69,
    pinned: 0,
    code: "GE",
    alpha3: "GEO",
    numeric: "268",
    translations: {
      data: {
        de: {
          name: "Georgien"
        },
        en: {
          name: "Georgia"
        },
        it: {
          name: "Georgia"
        }
      }
    }
  },
  {
    id: 70,
    pinned: 0,
    code: "GH",
    alpha3: "GHA",
    numeric: "288",
    translations: {
      data: {
        de: {
          name: "Ghana"
        },
        en: {
          name: "Ghana"
        },
        it: {
          name: "Ghana"
        }
      }
    }
  },
  {
    id: 71,
    pinned: 0,
    code: "GI",
    alpha3: "GIB",
    numeric: "292",
    translations: {
      data: {
        de: {
          name: "Gibraltar"
        },
        en: {
          name: "Gibraltar"
        },
        it: {
          name: "Gibilterra"
        }
      }
    }
  },
  {
    id: 72,
    pinned: 0,
    code: "GD",
    alpha3: "GRD",
    numeric: "308",
    translations: {
      data: {
        de: {
          name: "Grenada"
        },
        en: {
          name: "Grenada"
        },
        it: {
          name: "Grenada"
        }
      }
    }
  },
  {
    id: 73,
    pinned: 0,
    code: "GR",
    alpha3: "GRC",
    numeric: "300",
    translations: {
      data: {
        de: {
          name: "Griechenland"
        },
        en: {
          name: "Greece"
        },
        it: {
          name: "Grecia"
        }
      }
    }
  },
  {
    id: 74,
    pinned: 0,
    code: "GL",
    alpha3: "GRL",
    numeric: "304",
    translations: {
      data: {
        de: {
          name: "Grönland"
        },
        en: {
          name: "Greenland"
        },
        it: {
          name: "Groenlandia"
        }
      }
    }
  },
  {
    id: 75,
    pinned: 0,
    code: "GP",
    alpha3: "GLP",
    numeric: "312",
    translations: {
      data: {
        de: {
          name: "Guadeloupe"
        },
        en: {
          name: "Guadeloupe"
        },
        it: {
          name: "Guadalupa"
        }
      }
    }
  },
  {
    id: 76,
    pinned: 0,
    code: "GU",
    alpha3: "GUM",
    numeric: "316",
    translations: {
      data: {
        de: {
          name: "Guam"
        },
        en: {
          name: "Guam"
        },
        it: {
          name: "Guam"
        }
      }
    }
  },
  {
    id: 77,
    pinned: 0,
    code: "GT",
    alpha3: "GTM",
    numeric: "320",
    translations: {
      data: {
        de: {
          name: "Guatemala"
        },
        en: {
          name: "Guatemala"
        },
        it: {
          name: "Guatemala"
        }
      }
    }
  },
  {
    id: 78,
    pinned: 0,
    code: "GG",
    alpha3: "GGY",
    numeric: "831",
    translations: {
      data: {
        de: {
          name: "Guernsey"
        },
        en: {
          name: "Guernsey"
        },
        it: {
          name: "Guernsey"
        }
      }
    }
  },
  {
    id: 79,
    pinned: 0,
    code: "GN",
    alpha3: "GIN",
    numeric: "324",
    translations: {
      data: {
        de: {
          name: "Guinea"
        },
        en: {
          name: "Guinea"
        },
        it: {
          name: "Guinea"
        }
      }
    }
  },
  {
    id: 80,
    pinned: 0,
    code: "GW",
    alpha3: "GNB",
    numeric: "624",
    translations: {
      data: {
        de: {
          name: "Guinea-Bissau"
        },
        en: {
          name: "Guinea-Bissau"
        },
        it: {
          name: "Repubblica della Guinea Bissau"
        }
      }
    }
  },
  {
    id: 81,
    pinned: 0,
    code: "GY",
    alpha3: "GUY",
    numeric: "328",
    translations: {
      data: {
        de: {
          name: "Guyana"
        },
        en: {
          name: "Guyana"
        },
        it: {
          name: "Guyana"
        }
      }
    }
  },
  {
    id: 82,
    pinned: 0,
    code: "HT",
    alpha3: "HTI",
    numeric: "332",
    translations: {
      data: {
        de: {
          name: "Haiti"
        },
        en: {
          name: "Haiti"
        },
        it: {
          name: "Haiti"
        }
      }
    }
  },
  {
    id: 83,
    pinned: 0,
    code: "HM",
    alpha3: "HMD",
    numeric: "334",
    translations: {
      data: {
        de: {
          name: "Heard- und McDonald-Inseln"
        },
        en: {
          name: "Heard Island and McDonald Islands"
        },
        it: {
          name: "Isole Heard e McDonald"
        }
      }
    }
  },
  {
    id: 84,
    pinned: 0,
    code: "VA",
    alpha3: "VAT",
    numeric: "336",
    translations: {
      data: {
        de: {
          name: "Heiliger Stuhl (Vatikanstadt)"
        },
        en: {
          name: "Holy See"
        },
        it: {
          name: "Santa Sede (Città del Vaticano)"
        }
      }
    }
  },
  {
    id: 85,
    pinned: 0,
    code: "HN",
    alpha3: "HND",
    numeric: "340",
    translations: {
      data: {
        de: {
          name: "Honduras"
        },
        en: {
          name: "Honduras"
        },
        it: {
          name: "Honduras"
        }
      }
    }
  },
  {
    id: 86,
    pinned: 0,
    code: "HK",
    alpha3: "HKG",
    numeric: "344",
    translations: {
      data: {
        de: {
          name: "Hongkong"
        },
        en: {
          name: "Hong Kong"
        },
        it: {
          name: "Hong Kong"
        }
      }
    }
  },
  {
    id: 87,
    pinned: 0,
    code: "IN",
    alpha3: "IND",
    numeric: "356",
    translations: {
      data: {
        de: {
          name: "Indien"
        },
        en: {
          name: "India"
        },
        it: {
          name: "India"
        }
      }
    }
  },
  {
    id: 88,
    pinned: 0,
    code: "ID",
    alpha3: "IDN",
    numeric: "360",
    translations: {
      data: {
        de: {
          name: "Indonesien"
        },
        en: {
          name: "Indonesia"
        },
        it: {
          name: "Indonesia"
        }
      }
    }
  },
  {
    id: 89,
    pinned: 0,
    code: "IM",
    alpha3: "IMN",
    numeric: "833",
    translations: {
      data: {
        de: {
          name: "Insel Man"
        },
        en: {
          name: "Isle of Man"
        },
        it: {
          name: "Isola di Man"
        }
      }
    }
  },
  {
    id: 90,
    pinned: 0,
    code: "IQ",
    alpha3: "IRQ",
    numeric: "368",
    translations: {
      data: {
        de: {
          name: "Irak"
        },
        en: {
          name: "Iraq"
        },
        it: {
          name: "Irak"
        }
      }
    }
  },
  {
    id: 91,
    pinned: 0,
    code: "IR",
    alpha3: "IRN",
    numeric: "364",
    translations: {
      data: {
        de: {
          name: "Iran"
        },
        en: {
          name: "Iran (Islamic Republic of)"
        },
        it: {
          name: "Iran"
        }
      }
    }
  },
  {
    id: 92,
    pinned: 0,
    code: "IE",
    alpha3: "IRL",
    numeric: "372",
    translations: {
      data: {
        de: {
          name: "Irland"
        },
        en: {
          name: "Ireland"
        },
        it: {
          name: "Irlandia"
        }
      }
    }
  },
  {
    id: 93,
    pinned: 0,
    code: "IS",
    alpha3: "ISL",
    numeric: "352",
    translations: {
      data: {
        de: {
          name: "Island"
        },
        en: {
          name: "Iceland"
        },
        it: {
          name: "Islanda"
        }
      }
    }
  },
  {
    id: 94,
    pinned: 0,
    code: "IL",
    alpha3: "ISR",
    numeric: "376",
    translations: {
      data: {
        de: {
          name: "Israel"
        },
        en: {
          name: "Israel"
        },
        it: {
          name: "Israele"
        }
      }
    }
  },
  {
    id: 95,
    pinned: 1,
    code: "IT",
    alpha3: "ITA",
    numeric: "380",
    translations: {
      data: {
        de: {
          name: "Italien"
        },
        en: {
          name: "Italy"
        },
        it: {
          name: "Italia"
        }
      }
    }
  },
  {
    id: 96,
    pinned: 0,
    code: "JM",
    alpha3: "JAM",
    numeric: "388",
    translations: {
      data: {
        de: {
          name: "Jamaika"
        },
        en: {
          name: "Jamaica"
        },
        it: {
          name: "Giamaica"
        }
      }
    }
  },
  {
    id: 97,
    pinned: 0,
    code: "JP",
    alpha3: "JPN",
    numeric: "392",
    translations: {
      data: {
        de: {
          name: "Japan"
        },
        en: {
          name: "Japan"
        },
        it: {
          name: "Giappone"
        }
      }
    }
  },
  {
    id: 98,
    pinned: 0,
    code: "YE",
    alpha3: "YEM",
    numeric: "887",
    translations: {
      data: {
        de: {
          name: "Jemen"
        },
        en: {
          name: "Yemen"
        },
        it: {
          name: "Yemen"
        }
      }
    }
  },
  {
    id: 99,
    pinned: 0,
    code: "JE",
    alpha3: "JEY",
    numeric: "832",
    translations: {
      data: {
        de: {
          name: "Jersey"
        },
        en: {
          name: "Jersey"
        },
        it: {
          name: "Jersey"
        }
      }
    }
  },
  {
    id: 100,
    pinned: 0,
    code: "JO",
    alpha3: "JOR",
    numeric: "400",
    translations: {
      data: {
        de: {
          name: "Jordanien"
        },
        en: {
          name: "Jordan"
        },
        it: {
          name: "Giordania"
        }
      }
    }
  },
  {
    id: 101,
    pinned: 0,
    code: "KY",
    alpha3: "CYM",
    numeric: "136",
    translations: {
      data: {
        de: {
          name: "Kaimaninseln"
        },
        en: {
          name: "Cayman Islands"
        },
        it: {
          name: "Isole Cayman"
        }
      }
    }
  },
  {
    id: 102,
    pinned: 0,
    code: "KH",
    alpha3: "KHM",
    numeric: "116",
    translations: {
      data: {
        de: {
          name: "Kambodscha"
        },
        en: {
          name: "Cambodia"
        },
        it: {
          name: "Cambogia"
        }
      }
    }
  },
  {
    id: 103,
    pinned: 0,
    code: "CM",
    alpha3: "CMR",
    numeric: "120",
    translations: {
      data: {
        de: {
          name: "Kamerun"
        },
        en: {
          name: "Cameroon"
        },
        it: {
          name: "Camerun"
        }
      }
    }
  },
  {
    id: 104,
    pinned: 0,
    code: "CA",
    alpha3: "CAN",
    numeric: "124",
    translations: {
      data: {
        de: {
          name: "Kanada"
        },
        en: {
          name: "Canada"
        },
        it: {
          name: "Canada"
        }
      }
    }
  },
  {
    id: 105,
    pinned: 0,
    code: "CV",
    alpha3: "CPV",
    numeric: "132",
    translations: {
      data: {
        de: {
          name: "Kap Verde"
        },
        en: {
          name: "Cabo Verde"
        },
        it: {
          name: "Capo Verde"
        }
      }
    }
  },
  {
    id: 106,
    pinned: 0,
    code: "KZ",
    alpha3: "KAZ",
    numeric: "398",
    translations: {
      data: {
        de: {
          name: "Kasachstan"
        },
        en: {
          name: "Kazakhstan"
        },
        it: {
          name: "Kazakistan"
        }
      }
    }
  },
  {
    id: 107,
    pinned: 0,
    code: "QA",
    alpha3: "QAT",
    numeric: "634",
    translations: {
      data: {
        de: {
          name: "Katar"
        },
        en: {
          name: "Qatar"
        },
        it: {
          name: "Qatar"
        }
      }
    }
  },
  {
    id: 108,
    pinned: 0,
    code: "KE",
    alpha3: "KEN",
    numeric: "404",
    translations: {
      data: {
        de: {
          name: "Kenia"
        },
        en: {
          name: "Kenya"
        },
        it: {
          name: "Kenya"
        }
      }
    }
  },
  {
    id: 109,
    pinned: 0,
    code: "KG",
    alpha3: "KGZ",
    numeric: "417",
    translations: {
      data: {
        de: {
          name: "Kirgisistan"
        },
        en: {
          name: "Kyrgyzstan"
        },
        it: {
          name: "Kirghizistan"
        }
      }
    }
  },
  {
    id: 110,
    pinned: 0,
    code: "KI",
    alpha3: "KIR",
    numeric: "296",
    translations: {
      data: {
        de: {
          name: "Kiribati"
        },
        en: {
          name: "Kiribati"
        },
        it: {
          name: "Kiribati"
        }
      }
    }
  },
  {
    id: 111,
    pinned: 0,
    code: "UM",
    alpha3: "UMI",
    numeric: "581",
    translations: {
      data: {
        de: {
          name: "Kleinere amerikanische Überseeinseln"
        },
        en: {
          name: "United States Minor Outlying Islands"
        },
        it: {
          name: "Isole minori americane d'oltremare"
        }
      }
    }
  },
  {
    id: 112,
    pinned: 0,
    code: "CC",
    alpha3: "CCK",
    numeric: "166",
    translations: {
      data: {
        de: {
          name: "Kokosinseln (Keelinginseln)"
        },
        en: {
          name: "Cocos (Keeling) Islands"
        },
        it: {
          name: "Territorio delle Isole Cocos (Keeling)"
        }
      }
    }
  },
  {
    id: 113,
    pinned: 0,
    code: "CO",
    alpha3: "COL",
    numeric: "170",
    translations: {
      data: {
        de: {
          name: "Kolumbien"
        },
        en: {
          name: "Colombia"
        },
        it: {
          name: "Colombia"
        }
      }
    }
  },
  {
    id: 114,
    pinned: 0,
    code: "KM",
    alpha3: "COM",
    numeric: "174",
    translations: {
      data: {
        de: {
          name: "Komoren"
        },
        en: {
          name: "Comoros"
        },
        it: {
          name: "Comore"
        }
      }
    }
  },
  {
    id: 115,
    pinned: 0,
    code: "CG",
    alpha3: "COG",
    numeric: "178",
    translations: {
      data: {
        de: {
          name: "Kongo"
        },
        en: {
          name: "Congo"
        },
        it: {
          name: "Kongo"
        }
      }
    }
  },
  // {
  //     "id": 116,
  //     "pinned": 0,
  //     "code": "XK",
  //     "alpha3": "",
  //     "numeric": "",
  //     "translations": {
  //         "data": {
  //             "de": {
  //                 "name": "Kosovo"
  //             },
  //             "en": {
  //                 "name": "Kosovo"
  //             },
  //             "it": {
  //                 "name": "Kosovo"
  //             }
  //         }
  //     }
  // },
  {
    id: 117,
    pinned: 0,
    code: "HR",
    alpha3: "HRV",
    numeric: "191",
    translations: {
      data: {
        de: {
          name: "Kroatien"
        },
        en: {
          name: "Croatia"
        },
        it: {
          name: "Croatia"
        }
      }
    }
  },
  {
    id: 118,
    pinned: 0,
    code: "CU",
    alpha3: "CUB",
    numeric: "192",
    translations: {
      data: {
        de: {
          name: "Kuba"
        },
        en: {
          name: "Cuba"
        },
        it: {
          name: "Cuba"
        }
      }
    }
  },
  {
    id: 119,
    pinned: 0,
    code: "KW",
    alpha3: "KWT",
    numeric: "414",
    translations: {
      data: {
        de: {
          name: "Kuwait"
        },
        en: {
          name: "Kuwait"
        },
        it: {
          name: "Kuwait"
        }
      }
    }
  },
  {
    id: 120,
    pinned: 0,
    code: "LA",
    alpha3: "LAO",
    numeric: "418",
    translations: {
      data: {
        de: {
          name: "Laos"
        },
        en: {
          name: "Lao People's Democratic Republic"
        },
        it: {
          name: "Laos"
        }
      }
    }
  },
  {
    id: 121,
    pinned: 0,
    code: "LS",
    alpha3: "LSO",
    numeric: "426",
    translations: {
      data: {
        de: {
          name: "Lesotho"
        },
        en: {
          name: "Lesotho"
        },
        it: {
          name: "Lesotho"
        }
      }
    }
  },
  {
    id: 122,
    pinned: 0,
    code: "LV",
    alpha3: "LVA",
    numeric: "428",
    translations: {
      data: {
        de: {
          name: "Lettland"
        },
        en: {
          name: "Latvia"
        },
        it: {
          name: "Lettonia"
        }
      }
    }
  },
  {
    id: 123,
    pinned: 0,
    code: "LB",
    alpha3: "LBN",
    numeric: "422",
    translations: {
      data: {
        de: {
          name: "Libanon"
        },
        en: {
          name: "Lebanon"
        },
        it: {
          name: "Libano"
        }
      }
    }
  },
  {
    id: 124,
    pinned: 0,
    code: "LR",
    alpha3: "LBR",
    numeric: "430",
    translations: {
      data: {
        de: {
          name: "Liberia"
        },
        en: {
          name: "Liberia"
        },
        it: {
          name: "Liberia"
        }
      }
    }
  },
  {
    id: 125,
    pinned: 0,
    code: "LY",
    alpha3: "LBY",
    numeric: "434",
    translations: {
      data: {
        de: {
          name: "Libyen"
        },
        en: {
          name: "Libya"
        },
        it: {
          name: "Libia"
        }
      }
    }
  },
  {
    id: 126,
    pinned: 0,
    code: "LI",
    alpha3: "LIE",
    numeric: "438",
    translations: {
      data: {
        de: {
          name: "Liechtenstein"
        },
        en: {
          name: "Liechtenstein"
        },
        it: {
          name: "Liechtenstein"
        }
      }
    }
  },
  {
    id: 127,
    pinned: 0,
    code: "LT",
    alpha3: "LTU",
    numeric: "440",
    translations: {
      data: {
        de: {
          name: "Litauen"
        },
        en: {
          name: "Lithuania"
        },
        it: {
          name: "Lituania"
        }
      }
    }
  },
  {
    id: 128,
    pinned: 0,
    code: "LU",
    alpha3: "LUX",
    numeric: "442",
    translations: {
      data: {
        de: {
          name: "Luxemburg"
        },
        en: {
          name: "Luxembourg"
        },
        it: {
          name: "Lussemburgo"
        }
      }
    }
  },
  {
    id: 129,
    pinned: 0,
    code: "MO",
    alpha3: "MAC",
    numeric: "446",
    translations: {
      data: {
        de: {
          name: "Macau"
        },
        en: {
          name: "Macao"
        },
        it: {
          name: "Macao"
        }
      }
    }
  },
  {
    id: 130,
    pinned: 0,
    code: "MG",
    alpha3: "MDG",
    numeric: "450",
    translations: {
      data: {
        de: {
          name: "Madagaskar"
        },
        en: {
          name: "Madagascar"
        },
        it: {
          name: "Madagascar"
        }
      }
    }
  },
  {
    id: 131,
    pinned: 0,
    code: "MW",
    alpha3: "MWI",
    numeric: "454",
    translations: {
      data: {
        de: {
          name: "Malawi"
        },
        en: {
          name: "Malawi"
        },
        it: {
          name: "Malawi"
        }
      }
    }
  },
  {
    id: 132,
    pinned: 0,
    code: "MY",
    alpha3: "MYS",
    numeric: "458",
    translations: {
      data: {
        de: {
          name: "Malaysia"
        },
        en: {
          name: "Malaysia"
        },
        it: {
          name: "Malaysia"
        }
      }
    }
  },
  {
    id: 133,
    pinned: 0,
    code: "MV",
    alpha3: "MDV",
    numeric: "462",
    translations: {
      data: {
        de: {
          name: "Malediven"
        },
        en: {
          name: "Maldives"
        },
        it: {
          name: "Repubblica delle Maldive"
        }
      }
    }
  },
  {
    id: 134,
    pinned: 0,
    code: "ML",
    alpha3: "MLI",
    numeric: "466",
    translations: {
      data: {
        de: {
          name: "Mali"
        },
        en: {
          name: "Mali"
        },
        it: {
          name: "Mali"
        }
      }
    }
  },
  {
    id: 135,
    pinned: 0,
    code: "MT",
    alpha3: "MLT",
    numeric: "470",
    translations: {
      data: {
        de: {
          name: "Malta"
        },
        en: {
          name: "Malta"
        },
        it: {
          name: "Malta"
        }
      }
    }
  },
  {
    id: 136,
    pinned: 0,
    code: "MA",
    alpha3: "MAR",
    numeric: "504",
    translations: {
      data: {
        de: {
          name: "Marokko"
        },
        en: {
          name: "Morocco"
        },
        it: {
          name: "Marocco"
        }
      }
    }
  },
  {
    id: 137,
    pinned: 0,
    code: "MH",
    alpha3: "MHL",
    numeric: "584",
    translations: {
      data: {
        de: {
          name: "Marshallinseln"
        },
        en: {
          name: "Marshall Islands"
        },
        it: {
          name: "Isole Marshall"
        }
      }
    }
  },
  {
    id: 138,
    pinned: 0,
    code: "MQ",
    alpha3: "MTQ",
    numeric: "474",
    translations: {
      data: {
        de: {
          name: "Martinique"
        },
        en: {
          name: "Martinique"
        },
        it: {
          name: "Martinica"
        }
      }
    }
  },
  {
    id: 139,
    pinned: 0,
    code: "MR",
    alpha3: "MRT",
    numeric: "478",
    translations: {
      data: {
        de: {
          name: "Mauretanien"
        },
        en: {
          name: "Mauritania"
        },
        it: {
          name: "Mauritania"
        }
      }
    }
  },
  {
    id: 140,
    pinned: 0,
    code: "MU",
    alpha3: "MUS",
    numeric: "480",
    translations: {
      data: {
        de: {
          name: "Mauritius"
        },
        en: {
          name: "Mauritius"
        },
        it: {
          name: "Repubblica di Mauritius"
        }
      }
    }
  },
  {
    id: 141,
    pinned: 0,
    code: "YT",
    alpha3: "MYT",
    numeric: "175",
    translations: {
      data: {
        de: {
          name: "Mayotte"
        },
        en: {
          name: "Mayotte"
        },
        it: {
          name: "Mayotte"
        }
      }
    }
  },
  {
    id: 142,
    pinned: 0,
    code: "MK",
    alpha3: "MKD",
    numeric: "807",
    translations: {
      data: {
        de: {
          name: "Mazedonien"
        },
        en: {
          name: "Macedonia (the former Yugoslav Republic of)"
        },
        it: {
          name: "Macedonia"
        }
      }
    }
  },
  {
    id: 143,
    pinned: 0,
    code: "MX",
    alpha3: "MEX",
    numeric: "484",
    translations: {
      data: {
        de: {
          name: "Mexiko"
        },
        en: {
          name: "Mexico"
        },
        it: {
          name: "Messico"
        }
      }
    }
  },
  {
    id: 144,
    pinned: 0,
    code: "FM",
    alpha3: "FSM",
    numeric: "583",
    translations: {
      data: {
        de: {
          name: "Mikronesien (Föderierte Staaten von Mikronesien)"
        },
        en: {
          name: "Micronesia (Federated States of Micronesia)"
        },
        it: {
          name: "Micronesia (Stati Federati di Micronesia)"
        }
      }
    }
  },
  {
    id: 145,
    pinned: 0,
    code: "MD",
    alpha3: "MDA",
    numeric: "498",
    translations: {
      data: {
        de: {
          name: "Moldau"
        },
        en: {
          name: "Moldova"
        },
        it: {
          name: "Moldau"
        }
      }
    }
  },
  {
    id: 146,
    pinned: 0,
    code: "MC",
    alpha3: "MCO",
    numeric: "492",
    translations: {
      data: {
        de: {
          name: "Monaco"
        },
        en: {
          name: "Monaco"
        },
        it: {
          name: "Monaco"
        }
      }
    }
  },
  {
    id: 147,
    pinned: 0,
    code: "MN",
    alpha3: "MNG",
    numeric: "496",
    translations: {
      data: {
        de: {
          name: "Mongolei"
        },
        en: {
          name: "Mongolia"
        },
        it: {
          name: "Mongolia"
        }
      }
    }
  },
  {
    id: 148,
    pinned: 0,
    code: "ME",
    alpha3: "MNE",
    numeric: "499",
    translations: {
      data: {
        de: {
          name: "Montenegro"
        },
        en: {
          name: "Montenegro"
        },
        it: {
          name: "Montenegro"
        }
      }
    }
  },
  {
    id: 149,
    pinned: 0,
    code: "MS",
    alpha3: "MSR",
    numeric: "500",
    translations: {
      data: {
        de: {
          name: "Montserrat"
        },
        en: {
          name: "Montserrat"
        },
        it: {
          name: "Montserrat"
        }
      }
    }
  },
  {
    id: 150,
    pinned: 0,
    code: "MZ",
    alpha3: "MOZ",
    numeric: "508",
    translations: {
      data: {
        de: {
          name: "Mosambik"
        },
        en: {
          name: "Mozambique"
        },
        it: {
          name: "Mozambico"
        }
      }
    }
  },
  {
    id: 151,
    pinned: 0,
    code: "NA",
    alpha3: "NAM",
    numeric: "516",
    translations: {
      data: {
        de: {
          name: "Namibia"
        },
        en: {
          name: "Namibia"
        },
        it: {
          name: "Namibia"
        }
      }
    }
  },
  {
    id: 152,
    pinned: 0,
    code: "NR",
    alpha3: "NRU",
    numeric: "520",
    translations: {
      data: {
        de: {
          name: "Nauru"
        },
        en: {
          name: "Nauru"
        },
        it: {
          name: "Nauru"
        }
      }
    }
  },
  {
    id: 153,
    pinned: 0,
    code: "NP",
    alpha3: "NPL",
    numeric: "524",
    translations: {
      data: {
        de: {
          name: "Nepal"
        },
        en: {
          name: "Nepal"
        },
        it: {
          name: "Nepal"
        }
      }
    }
  },
  {
    id: 154,
    pinned: 0,
    code: "NC",
    alpha3: "NCL",
    numeric: "540",
    translations: {
      data: {
        de: {
          name: "Neukaledonien"
        },
        en: {
          name: "New Caledonia"
        },
        it: {
          name: "Nuova Caledonia"
        }
      }
    }
  },
  {
    id: 155,
    pinned: 0,
    code: "NZ",
    alpha3: "NZL",
    numeric: "554",
    translations: {
      data: {
        de: {
          name: "Neuseeland"
        },
        en: {
          name: "New Zealand"
        },
        it: {
          name: "Nuova Zelanda"
        }
      }
    }
  },
  {
    id: 156,
    pinned: 0,
    code: "NI",
    alpha3: "NIC",
    numeric: "558",
    translations: {
      data: {
        de: {
          name: "Nicaragua"
        },
        en: {
          name: "Nicaragua"
        },
        it: {
          name: "Nicaragua"
        }
      }
    }
  },
  {
    id: 157,
    pinned: 0,
    code: "NL",
    alpha3: "NLD",
    numeric: "528",
    translations: {
      data: {
        de: {
          name: "Niederlande"
        },
        en: {
          name: "Netherlands"
        },
        it: {
          name: "Paesi Bassi"
        }
      }
    }
  },
  {
    id: 158,
    pinned: 0,
    code: "NE",
    alpha3: "NER",
    numeric: "562",
    translations: {
      data: {
        de: {
          name: "Niger"
        },
        en: {
          name: "Niger"
        },
        it: {
          name: "Niger"
        }
      }
    }
  },
  {
    id: 159,
    pinned: 0,
    code: "NG",
    alpha3: "NGA",
    numeric: "566",
    translations: {
      data: {
        de: {
          name: "Nigeria"
        },
        en: {
          name: "Nigeria"
        },
        it: {
          name: "Nigeria"
        }
      }
    }
  },
  {
    id: 160,
    pinned: 0,
    code: "NU",
    alpha3: "NIU",
    numeric: "570",
    translations: {
      data: {
        de: {
          name: "Niue"
        },
        en: {
          name: "Niue"
        },
        it: {
          name: "Niue"
        }
      }
    }
  },
  {
    id: 161,
    pinned: 0,
    code: "KP",
    alpha3: "PRK",
    numeric: "408",
    translations: {
      data: {
        de: {
          name: "Nordkorea"
        },
        en: {
          name: "North Korea (Democratic People's Republic of Korea)"
        },
        it: {
          name: "Corea del Nord"
        }
      }
    }
  },
  {
    id: 162,
    pinned: 0,
    code: "MP",
    alpha3: "MNP",
    numeric: "580",
    translations: {
      data: {
        de: {
          name: "Nördliche Marianen"
        },
        en: {
          name: "Northern Mariana Islands"
        },
        it: {
          name: "Isole Marianne Settentrionali"
        }
      }
    }
  },
  {
    id: 163,
    pinned: 0,
    code: "NF",
    alpha3: "NFK",
    numeric: "574",
    translations: {
      data: {
        de: {
          name: "Norfolkinsel"
        },
        en: {
          name: "Norfolk Island"
        },
        it: {
          name: "Isola Norfolk"
        }
      }
    }
  },
  {
    id: 164,
    pinned: 0,
    code: "NO",
    alpha3: "NOR",
    numeric: "578",
    translations: {
      data: {
        de: {
          name: "Norwegen"
        },
        en: {
          name: "Norway"
        },
        it: {
          name: "Norvegia"
        }
      }
    }
  },
  {
    id: 165,
    pinned: 0,
    code: "OM",
    alpha3: "OMN",
    numeric: "512",
    translations: {
      data: {
        de: {
          name: "Oman"
        },
        en: {
          name: "Oman"
        },
        it: {
          name: "Oman"
        }
      }
    }
  },
  {
    id: 166,
    pinned: 1,
    code: "AT",
    alpha3: "AUT",
    numeric: "040",
    translations: {
      data: {
        de: {
          name: "Österreich"
        },
        en: {
          name: "Austria"
        },
        it: {
          name: "Austria"
        }
      }
    }
  },
  {
    id: 167,
    pinned: 0,
    code: "PK",
    alpha3: "PAK",
    numeric: "586",
    translations: {
      data: {
        de: {
          name: "Pakistan"
        },
        en: {
          name: "Pakistan"
        },
        it: {
          name: "Pakistan"
        }
      }
    }
  },
  {
    id: 168,
    pinned: 0,
    code: "PW",
    alpha3: "PLW",
    numeric: "585",
    translations: {
      data: {
        de: {
          name: "Palau"
        },
        en: {
          name: "Palau"
        },
        it: {
          name: "Palau"
        }
      }
    }
  },
  {
    id: 169,
    pinned: 0,
    code: "PA",
    alpha3: "PAN",
    numeric: "591",
    translations: {
      data: {
        de: {
          name: "Panama"
        },
        en: {
          name: "Panama"
        },
        it: {
          name: "Panama"
        }
      }
    }
  },
  {
    id: 170,
    pinned: 0,
    code: "PG",
    alpha3: "PNG",
    numeric: "598",
    translations: {
      data: {
        de: {
          name: "Papua-Neuguinea"
        },
        en: {
          name: "Papua New Guinea"
        },
        it: {
          name: "Papua Nuova Guinea"
        }
      }
    }
  },
  {
    id: 171,
    pinned: 0,
    code: "PY",
    alpha3: "PRY",
    numeric: "600",
    translations: {
      data: {
        de: {
          name: "Paraguay"
        },
        en: {
          name: "Paraguay"
        },
        it: {
          name: "Paraguay"
        }
      }
    }
  },
  {
    id: 172,
    pinned: 0,
    code: "PE",
    alpha3: "PER",
    numeric: "604",
    translations: {
      data: {
        de: {
          name: "Peru"
        },
        en: {
          name: "Peru"
        },
        it: {
          name: "Perù"
        }
      }
    }
  },
  {
    id: 173,
    pinned: 0,
    code: "PH",
    alpha3: "PHL",
    numeric: "608",
    translations: {
      data: {
        de: {
          name: "Philippinen"
        },
        en: {
          name: "Philippines"
        },
        it: {
          name: "Filippine"
        }
      }
    }
  },
  {
    id: 174,
    pinned: 0,
    code: "PN",
    alpha3: "PCN",
    numeric: "612",
    translations: {
      data: {
        de: {
          name: "Pitcairn"
        },
        en: {
          name: "Pitcairn"
        },
        it: {
          name: "Pitcairn"
        }
      }
    }
  },
  {
    id: 175,
    pinned: 0,
    code: "PL",
    alpha3: "POL",
    numeric: "616",
    translations: {
      data: {
        de: {
          name: "Polen"
        },
        en: {
          name: "Poland"
        },
        it: {
          name: "Polonia"
        }
      }
    }
  },
  {
    id: 176,
    pinned: 0,
    code: "PT",
    alpha3: "PRT",
    numeric: "620",
    translations: {
      data: {
        de: {
          name: "Portugal"
        },
        en: {
          name: "Portugal"
        },
        it: {
          name: "Portogallo"
        }
      }
    }
  },
  {
    id: 177,
    pinned: 0,
    code: "PR",
    alpha3: "PRI",
    numeric: "630",
    translations: {
      data: {
        de: {
          name: "Puerto Rico"
        },
        en: {
          name: "Puerto Rico"
        },
        it: {
          name: "Puerto Rico"
        }
      }
    }
  },
  {
    id: 178,
    pinned: 0,
    code: "RE",
    alpha3: "REU",
    numeric: "638",
    translations: {
      data: {
        de: {
          name: "Réunion"
        },
        en: {
          name: "Réunion"
        },
        it: {
          name: "Riunione"
        }
      }
    }
  },
  {
    id: 179,
    pinned: 0,
    code: "RW",
    alpha3: "RWA",
    numeric: "646",
    translations: {
      data: {
        de: {
          name: "Ruanda"
        },
        en: {
          name: "Rwanda"
        },
        it: {
          name: "Ruanda"
        }
      }
    }
  },
  {
    id: 180,
    pinned: 0,
    code: "RO",
    alpha3: "ROU",
    numeric: "642",
    translations: {
      data: {
        de: {
          name: "Rumänien"
        },
        en: {
          name: "Romania"
        },
        it: {
          name: "Rumänien"
        }
      }
    }
  },
  {
    id: 181,
    pinned: 0,
    code: "RU",
    alpha3: "RUS",
    numeric: "643",
    translations: {
      data: {
        de: {
          name: "Russland"
        },
        en: {
          name: "Russia"
        },
        it: {
          name: "Russia"
        }
      }
    }
  },
  {
    id: 182,
    pinned: 0,
    code: "SB",
    alpha3: "SLB",
    numeric: "090",
    translations: {
      data: {
        de: {
          name: "Salomonen"
        },
        en: {
          name: "Solomon Islands"
        },
        it: {
          name: "Isole Salomone"
        }
      }
    }
  },
  {
    id: 183,
    pinned: 0,
    code: "ZM",
    alpha3: "ZMB",
    numeric: "894",
    translations: {
      data: {
        de: {
          name: "Sambia"
        },
        en: {
          name: "Zambia"
        },
        it: {
          name: "Zambia"
        }
      }
    }
  },
  {
    id: 184,
    pinned: 0,
    code: "WS",
    alpha3: "WSM",
    numeric: "882",
    translations: {
      data: {
        de: {
          name: "Samoa"
        },
        en: {
          name: "Samoa"
        },
        it: {
          name: "Samoa"
        }
      }
    }
  },
  {
    id: 185,
    pinned: 0,
    code: "SM",
    alpha3: "SMR",
    numeric: "674",
    translations: {
      data: {
        de: {
          name: "San Marino"
        },
        en: {
          name: "San Marino"
        },
        it: {
          name: "San Marino"
        }
      }
    }
  },
  {
    id: 186,
    pinned: 0,
    code: "BL",
    alpha3: "BLM",
    numeric: "652",
    translations: {
      data: {
        de: {
          name: "Sankt Bartholomäus"
        },
        en: {
          name: "Saint Barthélemy"
        },
        it: {
          name: "Saint-Barthélemy"
        }
      }
    }
  },
  {
    id: 187,
    pinned: 0,
    code: "ST",
    alpha3: "STP",
    numeric: "678",
    translations: {
      data: {
        de: {
          name: "São Tomé und Príncipe"
        },
        en: {
          name: "Sao Tome and Principe"
        },
        it: {
          name: "São Tomé e Príncipe"
        }
      }
    }
  },
  {
    id: 188,
    pinned: 0,
    code: "SA",
    alpha3: "SAU",
    numeric: "682",
    translations: {
      data: {
        de: {
          name: "Saudi-Arabien"
        },
        en: {
          name: "Saudi Arabia"
        },
        it: {
          name: "Arabia Saudita"
        }
      }
    }
  },
  {
    id: 189,
    pinned: 0,
    code: "SE",
    alpha3: "SWE",
    numeric: "752",
    translations: {
      data: {
        de: {
          name: "Schweden"
        },
        en: {
          name: "Sweden"
        },
        it: {
          name: "Svezia"
        }
      }
    }
  },
  {
    id: 190,
    pinned: 1,
    code: "CH",
    alpha3: "CHE",
    numeric: "756",
    translations: {
      data: {
        de: {
          name: "Schweiz"
        },
        en: {
          name: "Switzerland"
        },
        it: {
          name: "Svizzera"
        }
      }
    }
  },
  {
    id: 191,
    pinned: 0,
    code: "SN",
    alpha3: "SEN",
    numeric: "686",
    translations: {
      data: {
        de: {
          name: "Senegal"
        },
        en: {
          name: "Senegal"
        },
        it: {
          name: "Senegal"
        }
      }
    }
  },
  {
    id: 192,
    pinned: 0,
    code: "RS",
    alpha3: "SRB",
    numeric: "688",
    translations: {
      data: {
        de: {
          name: "Serbien"
        },
        en: {
          name: "Serbia"
        },
        it: {
          name: "Serbia"
        }
      }
    }
  },
  {
    id: 193,
    pinned: 0,
    code: "SC",
    alpha3: "SYC",
    numeric: "690",
    translations: {
      data: {
        de: {
          name: "Seychellen"
        },
        en: {
          name: "Seychelles"
        },
        it: {
          name: "Seychelles"
        }
      }
    }
  },
  {
    id: 194,
    pinned: 0,
    code: "SL",
    alpha3: "SLE",
    numeric: "694",
    translations: {
      data: {
        de: {
          name: "Sierra Leone"
        },
        en: {
          name: "Sierra Leone"
        },
        it: {
          name: "Sierra Leone"
        }
      }
    }
  },
  {
    id: 195,
    pinned: 0,
    code: "ZW",
    alpha3: "ZWE",
    numeric: "716",
    translations: {
      data: {
        de: {
          name: "Simbabwe"
        },
        en: {
          name: "Zimbabwe"
        },
        it: {
          name: "Zimbabwe"
        }
      }
    }
  },
  {
    id: 196,
    pinned: 0,
    code: "SG",
    alpha3: "SGP",
    numeric: "702",
    translations: {
      data: {
        de: {
          name: "Singapur"
        },
        en: {
          name: "Singapore"
        },
        it: {
          name: "Singapore"
        }
      }
    }
  },
  {
    id: 197,
    pinned: 0,
    code: "SK",
    alpha3: "SVK",
    numeric: "703",
    translations: {
      data: {
        de: {
          name: "Slowakei"
        },
        en: {
          name: "Slovakia"
        },
        it: {
          name: "Slovacchia"
        }
      }
    }
  },
  {
    id: 198,
    pinned: 0,
    code: "SI",
    alpha3: "SVN",
    numeric: "705",
    translations: {
      data: {
        de: {
          name: "Slowenien"
        },
        en: {
          name: "Slovenia"
        },
        it: {
          name: "Slovenia"
        }
      }
    }
  },
  {
    id: 199,
    pinned: 0,
    code: "SO",
    alpha3: "SOM",
    numeric: "706",
    translations: {
      data: {
        de: {
          name: "Somalia"
        },
        en: {
          name: "Somalia"
        },
        it: {
          name: "Somalia"
        }
      }
    }
  },
  {
    id: 200,
    pinned: 0,
    code: "ES",
    alpha3: "ESP",
    numeric: "724",
    translations: {
      data: {
        de: {
          name: "Spanien"
        },
        en: {
          name: "Spain"
        },
        it: {
          name: "Spagna"
        }
      }
    }
  },
  {
    id: 201,
    pinned: 0,
    code: "SJ",
    alpha3: "SJM",
    numeric: "744",
    translations: {
      data: {
        de: {
          name: "Spitzbergen"
        },
        en: {
          name: "Svalbard"
        },
        it: {
          name: "Svalbard"
        }
      }
    }
  },
  {
    id: 202,
    pinned: 0,
    code: "LK",
    alpha3: "LKA",
    numeric: "144",
    translations: {
      data: {
        de: {
          name: "Sri Lanka"
        },
        en: {
          name: "Sri Lanka"
        },
        it: {
          name: "Sri Lanka"
        }
      }
    }
  },
  {
    id: 203,
    pinned: 0,
    code: "SH",
    alpha3: "SHN",
    numeric: "654",
    translations: {
      data: {
        de: {
          name: "St. Helena, Ascension und Tristan da Cunha"
        },
        en: {
          name: "Saint Helena, Ascension and Tristan da Cunha"
        },
        it: {
          name: "Sant'Elena, Ascensione e Tristan da Cunha"
        }
      }
    }
  },
  {
    id: 204,
    pinned: 0,
    code: "KN",
    alpha3: "KNA",
    numeric: "659",
    translations: {
      data: {
        de: {
          name: "St. Kitts und Nevis"
        },
        en: {
          name: "Saint Kitts and Nevis"
        },
        it: {
          name: "Saint Kitts e Nevis"
        }
      }
    }
  },
  {
    id: 205,
    pinned: 0,
    code: "LC",
    alpha3: "LCA",
    numeric: "662",
    translations: {
      data: {
        de: {
          name: "St. Lucia"
        },
        en: {
          name: "Saint Lucia"
        },
        it: {
          name: "Saint Lucia"
        }
      }
    }
  },
  {
    id: 206,
    pinned: 0,
    code: "SX",
    alpha3: "SXM",
    numeric: "534",
    translations: {
      data: {
        de: {
          name: "Sint Maarten"
        },
        en: {
          name: "Sint Maarten"
        },
        it: {
          name: "Sint Maarten"
        }
      }
    }
  },
  {
    id: 207,
    pinned: 0,
    code: "MF",
    alpha3: "MAF",
    numeric: "663",
    translations: {
      data: {
        de: {
          name: "St. Martin"
        },
        en: {
          name: "Saint Martin (French part)"
        },
        it: {
          name: "Saint Martin"
        }
      }
    }
  },
  {
    id: 208,
    pinned: 0,
    code: "PM",
    alpha3: "SPM",
    numeric: "666",
    translations: {
      data: {
        de: {
          name: "St. Pierre und Miquelon"
        },
        en: {
          name: "Saint Pierre and Miquelon"
        },
        it: {
          name: "Saint-Pierre e Miquelon"
        }
      }
    }
  },
  {
    id: 209,
    pinned: 0,
    code: "VC",
    alpha3: "VCT",
    numeric: "670",
    translations: {
      data: {
        de: {
          name: "St. Vincent und die Grenadinen"
        },
        en: {
          name: "Saint Vincent and the Grenadines"
        },
        it: {
          name: "Saint Vincent e Grenadine"
        }
      }
    }
  },
  {
    id: 214,
    pinned: 0,
    code: "SS",
    alpha3: "SSD",
    numeric: "728",
    translations: {
      data: {
        de: {
          name: "Süd-Sudan"
        },
        en: {
          name: "South Sudan"
        },
        it: {
          name: "Sudan del Sud"
        }
      }
    }
  },
  {
    id: 210,
    pinned: 0,
    code: "ZA",
    alpha3: "ZAF",
    numeric: "710",
    translations: {
      data: {
        de: {
          name: "Südafrika"
        },
        en: {
          name: "South Africa"
        },
        it: {
          name: "Sudafrica"
        }
      }
    }
  },
  {
    id: 211,
    pinned: 0,
    code: "SD",
    alpha3: "SDN",
    numeric: "729",
    translations: {
      data: {
        de: {
          name: "Sudan"
        },
        en: {
          name: "Sudan"
        },
        it: {
          name: "Sudan"
        }
      }
    }
  },
  {
    id: 212,
    pinned: 0,
    code: "GS",
    alpha3: "SGS",
    numeric: "239",
    translations: {
      data: {
        de: {
          name: "Südgeorgien und die südlichen Sandwichinseln"
        },
        en: {
          name: "South Georgia and the South Sandwich Islands"
        },
        it: {
          name: "Georgia del Sud e Isole Sandwich Australi"
        }
      }
    }
  },
  {
    id: 213,
    pinned: 0,
    code: "KR",
    alpha3: "KOR",
    numeric: "410",
    translations: {
      data: {
        de: {
          name: "Südkorea"
        },
        en: {
          name: "South Korea"
        },
        it: {
          name: "Corea del Sud"
        }
      }
    }
  },
  {
    id: 215,
    pinned: 0,
    code: "SR",
    alpha3: "SUR",
    numeric: "740",
    translations: {
      data: {
        de: {
          name: "Surinam"
        },
        en: {
          name: "Suriname"
        },
        it: {
          name: "Surinam"
        }
      }
    }
  },
  {
    id: 216,
    pinned: 0,
    code: "SZ",
    alpha3: "SWZ",
    numeric: "748",
    translations: {
      data: {
        de: {
          name: "Swasiland"
        },
        en: {
          name: "Swaziland"
        },
        it: {
          name: "Swaziland"
        }
      }
    }
  },
  {
    id: 217,
    pinned: 0,
    code: "SY",
    alpha3: "SYR",
    numeric: "760",
    translations: {
      data: {
        de: {
          name: "Syrien"
        },
        en: {
          name: "Syria"
        },
        it: {
          name: "Siria"
        }
      }
    }
  },
  {
    id: 218,
    pinned: 0,
    code: "TJ",
    alpha3: "TJK",
    numeric: "762",
    translations: {
      data: {
        de: {
          name: "Tadschikistan"
        },
        en: {
          name: "Tajikistan"
        },
        it: {
          name: "Tagikistan"
        }
      }
    }
  },
  {
    id: 219,
    pinned: 0,
    code: "TW",
    alpha3: "TWN",
    numeric: "158",
    translations: {
      data: {
        de: {
          name: "Taiwan"
        },
        en: {
          name: "Taiwan"
        },
        it: {
          name: "Taiwan"
        }
      }
    }
  },
  {
    id: 220,
    pinned: 0,
    code: "TZ",
    alpha3: "TZA",
    numeric: "834",
    translations: {
      data: {
        de: {
          name: "Tansania"
        },
        en: {
          name: "Tanzania"
        },
        it: {
          name: "Tanzania"
        }
      }
    }
  },
  {
    id: 221,
    pinned: 0,
    code: "TH",
    alpha3: "THA",
    numeric: "764",
    translations: {
      data: {
        de: {
          name: "Thailand"
        },
        en: {
          name: "Thailand"
        },
        it: {
          name: "Thailandia"
        }
      }
    }
  },
  {
    id: 222,
    pinned: 0,
    code: "TL",
    alpha3: "TLS",
    numeric: "626",
    translations: {
      data: {
        de: {
          name: "Timor-Leste"
        },
        en: {
          name: "East Timor"
        },
        it: {
          name: "Timor Est"
        }
      }
    }
  },
  {
    id: 223,
    pinned: 0,
    code: "TG",
    alpha3: "TGO",
    numeric: "768",
    translations: {
      data: {
        de: {
          name: "Togo"
        },
        en: {
          name: "Togo"
        },
        it: {
          name: "Togo"
        }
      }
    }
  },
  {
    id: 224,
    pinned: 0,
    code: "TK",
    alpha3: "TKL",
    numeric: "772",
    translations: {
      data: {
        de: {
          name: "Tokelau"
        },
        en: {
          name: "Tokelau"
        },
        it: {
          name: "Tokelau"
        }
      }
    }
  },
  {
    id: 225,
    pinned: 0,
    code: "TO",
    alpha3: "TON",
    numeric: "776",
    translations: {
      data: {
        de: {
          name: "Tonga"
        },
        en: {
          name: "Tonga"
        },
        it: {
          name: "Tonga"
        }
      }
    }
  },
  {
    id: 226,
    pinned: 0,
    code: "TT",
    alpha3: "TTO",
    numeric: "780",
    translations: {
      data: {
        de: {
          name: "Trinidad und Tobago"
        },
        en: {
          name: "Trinidad and Tobago"
        },
        it: {
          name: "Trinidad e Tobago"
        }
      }
    }
  },
  {
    id: 227,
    pinned: 0,
    code: "TD",
    alpha3: "TCD",
    numeric: "148",
    translations: {
      data: {
        de: {
          name: "Tschad"
        },
        en: {
          name: "Chad"
        },
        it: {
          name: "Ciad"
        }
      }
    }
  },
  {
    id: 228,
    pinned: 0,
    code: "CZ",
    alpha3: "CZE",
    numeric: "203",
    translations: {
      data: {
        de: {
          name: "Tschechische Republik"
        },
        en: {
          name: "Czech Republic"
        },
        it: {
          name: "Repubblica Ceca"
        }
      }
    }
  },
  {
    id: 229,
    pinned: 0,
    code: "TN",
    alpha3: "TUN",
    numeric: "788",
    translations: {
      data: {
        de: {
          name: "Tunesien"
        },
        en: {
          name: "Tunisia"
        },
        it: {
          name: "Tunisia"
        }
      }
    }
  },
  {
    id: 230,
    pinned: 0,
    code: "TR",
    alpha3: "TUR",
    numeric: "792",
    translations: {
      data: {
        de: {
          name: "Türkei"
        },
        en: {
          name: "Turkey"
        },
        it: {
          name: "Turchia"
        }
      }
    }
  },
  {
    id: 231,
    pinned: 0,
    code: "TM",
    alpha3: "TKM",
    numeric: "795",
    translations: {
      data: {
        de: {
          name: "Turkmenistan"
        },
        en: {
          name: "Turkmenistan"
        },
        it: {
          name: "Turkmenistan"
        }
      }
    }
  },
  {
    id: 232,
    pinned: 0,
    code: "TC",
    alpha3: "TCA",
    numeric: "796",
    translations: {
      data: {
        de: {
          name: "Turks- und Caicosinseln"
        },
        en: {
          name: "Turks and Caicos Islands"
        },
        it: {
          name: "Isole Turks e Caicos"
        }
      }
    }
  },
  {
    id: 233,
    pinned: 0,
    code: "TV",
    alpha3: "TUV",
    numeric: "798",
    translations: {
      data: {
        de: {
          name: "Tuvalu"
        },
        en: {
          name: "Tuvalu"
        },
        it: {
          name: "Tuvalu"
        }
      }
    }
  },
  {
    id: 234,
    pinned: 0,
    code: "UG",
    alpha3: "UGA",
    numeric: "800",
    translations: {
      data: {
        de: {
          name: "Uganda"
        },
        en: {
          name: "Uganda"
        },
        it: {
          name: "Uganda"
        }
      }
    }
  },
  {
    id: 235,
    pinned: 0,
    code: "UA",
    alpha3: "UKR",
    numeric: "804",
    translations: {
      data: {
        de: {
          name: "Ukraine"
        },
        en: {
          name: "Ukraine"
        },
        it: {
          name: "Ucraina"
        }
      }
    }
  },
  {
    id: 236,
    pinned: 0,
    code: "HU",
    alpha3: "HUN",
    numeric: "348",
    translations: {
      data: {
        de: {
          name: "Ungarn"
        },
        en: {
          name: "Hungary"
        },
        it: {
          name: "Ungheria"
        }
      }
    }
  },
  {
    id: 237,
    pinned: 0,
    code: "UY",
    alpha3: "URY",
    numeric: "858",
    translations: {
      data: {
        de: {
          name: "Uruguay"
        },
        en: {
          name: "Uruguay"
        },
        it: {
          name: "Uruguay"
        }
      }
    }
  },
  {
    id: 238,
    pinned: 0,
    code: "US",
    alpha3: "USA",
    numeric: "840",
    translations: {
      data: {
        de: {
          name: "USA"
        },
        en: {
          name: "United States of America"
        },
        it: {
          name: "USA"
        }
      }
    }
  },
  {
    id: 239,
    pinned: 0,
    code: "UZ",
    alpha3: "UZB",
    numeric: "860",
    translations: {
      data: {
        de: {
          name: "Usbekistan"
        },
        en: {
          name: "Uzbekistan"
        },
        it: {
          name: "Uzbekistan"
        }
      }
    }
  },
  {
    id: 240,
    pinned: 0,
    code: "VU",
    alpha3: "VUT",
    numeric: "548",
    translations: {
      data: {
        de: {
          name: "Vanuatu"
        },
        en: {
          name: "Vanuatu"
        },
        it: {
          name: "Vanuatu"
        }
      }
    }
  },
  {
    id: 241,
    pinned: 0,
    code: "VE",
    alpha3: "VEN",
    numeric: "862",
    translations: {
      data: {
        de: {
          name: "Venezuela"
        },
        en: {
          name: "Venezuela"
        },
        it: {
          name: "Venezuela"
        }
      }
    }
  },
  {
    id: 242,
    pinned: 0,
    code: "AE",
    alpha3: "ARE",
    numeric: "784",
    translations: {
      data: {
        de: {
          name: "Vereinigte Arabische Emirate"
        },
        en: {
          name: "United Arab Emirates"
        },
        it: {
          name: "Emirati Arabi Uniti"
        }
      }
    }
  },
  {
    id: 243,
    pinned: 0,
    code: "GB",
    alpha3: "GBR",
    numeric: "826",
    translations: {
      data: {
        de: {
          name: "Vereinigtes Königreich"
        },
        en: {
          name: "United Kingdom"
        },
        it: {
          name: "Regno Unito"
        }
      }
    }
  },
  {
    id: 244,
    pinned: 0,
    code: "VN",
    alpha3: "VNM",
    numeric: "704",
    translations: {
      data: {
        de: {
          name: "Vietnam"
        },
        en: {
          name: "Viet Nam"
        },
        it: {
          name: "Vietnam"
        }
      }
    }
  },
  {
    id: 245,
    pinned: 0,
    code: "WF",
    alpha3: "WLF",
    numeric: "876",
    translations: {
      data: {
        de: {
          name: "Wallis und Futuna"
        },
        en: {
          name: "Wallis and Futuna"
        },
        it: {
          name: "Wallis e Futuna"
        }
      }
    }
  },
  {
    id: 246,
    pinned: 0,
    code: "CX",
    alpha3: "CXR",
    numeric: "162",
    translations: {
      data: {
        de: {
          name: "Weihnachtsinsel"
        },
        en: {
          name: "Christmas Island"
        },
        it: {
          name: "Isola di Natale"
        }
      }
    }
  },
  {
    id: 68,
    pinned: 0,
    code: "PS",
    alpha3: "PSE",
    numeric: "275",
    translations: {
      data: {
        de: {
          name: "Westjordanland"
        },
        en: {
          name: "Palestine"
        },
        it: {
          name: "Cisgiordania"
        }
      }
    }
  },
  {
    id: 247,
    pinned: 0,
    code: "EH",
    alpha3: "ESH",
    numeric: "732",
    translations: {
      data: {
        de: {
          name: "Westsahara"
        },
        en: {
          name: "Western Sahara"
        },
        it: {
          name: "Sahara Occidentale"
        }
      }
    }
  },
  {
    id: 248,
    pinned: 0,
    code: "CF",
    alpha3: "CAF",
    numeric: "140",
    translations: {
      data: {
        de: {
          name: "Zentralafrikanische Republik"
        },
        en: {
          name: "Central African Republic"
        },
        it: {
          name: "Repubblica Centrafricana"
        }
      }
    }
  },
  {
    id: 249,
    pinned: 0,
    code: "CY",
    alpha3: "CYP",
    numeric: "196",
    translations: {
      data: {
        de: {
          name: "Zypern"
        },
        en: {
          name: "Cyprus"
        },
        it: {
          name: "Cipro"
        }
      }
    }
  }
];
