import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from "axios";

import messages from '@/i18n'

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
});

axios.interceptors.request.use(
    request => {
        if (request.headers['Accept-Language'] == undefined) {
            request.headers['Accept-Language'] = i18n.locale;
        }
        return request;
    },
    error => {
        return Promise.reject(error)
    }
);


export default i18n;