var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"modal",staticClass:"modal fade",attrs:{"id":"CarouselModal","tabindex":"-1","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body p-0"},[_c('div',{ref:"carousel",staticClass:"carousel slide",attrs:{"id":"CarouselSlider","data-ride":"carousel"}},[_c('div',{staticClass:"carousel-inner"},[(_vm.media.mainImage)?_c('div',{staticClass:"carousel-item active"},[_c('div',{staticClass:"picture-wrapper ratio ratio-4x3",style:({
                'background-image':
                  'url(' + (_vm.media.mainImage.url + '?d=400x300)')
              })})]):_vm._e(),(_vm.media.mediaList && _vm.media.mediaList.length)?_vm._l((_vm.media.mediaList),function(media){return _c('div',{key:media.public_id,staticClass:"carousel-item"},[(media.type == 'pano' && media.url_public)?[_c('div',{staticClass:"picture-wrapper ratio ratio-4x3"},[_c('iframe',{staticClass:"embed-responsive-item",attrs:{"src":media.url_public,"allowfullscreen":""}})])]:(media.type === 'video')?[_c('div',{staticClass:"picture-wrapper ratio ratio-4x3"},[(media.provider === 'cdn')?_c('video',{staticClass:"embed-responsive-item",attrs:{"poster":media.url,"autoplay":"","muted":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":media.url_public,"type":"video/mp4"}})]):(media.provider === 'youtube')?_c('iframe',{attrs:{"allowfullscreen":"","frameborder":"0","src":'https://www.youtube.com/embed/' +
                      media.public_id +
                      '?autoplay=1&controls=0&crop=off&modestbranding=1&mute=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&hd=1&playlist=' +
                      media.public_id}}):(media.provider === 'vimeo')?_c('iframe',{staticClass:"video-item",attrs:{"allowfullscreen":"","src":'https://player.vimeo.com/video/' +
                      media.public_id +
                      '?autoplay=1&background=1&muted=1&loop=1'}}):_vm._e()])]:_c('div',{staticClass:"picture-wrapper ratio ratio-4x3",style:({
                  'background-image': 'url(' + (media.url + '?d=400x300)')
                })})],2)}):_vm._e()],2),[_c('a',{staticClass:"carousel-control-prev",attrs:{"role":"button"},on:{"click":function($event){return _vm.prev()}}},[_c('span',{staticClass:"carousel-control-prev-icon",attrs:{"aria-hidden":"true"}})]),_c('a',{staticClass:"carousel-control-next",attrs:{"role":"button"},on:{"click":function($event){return _vm.next()}}},[_c('span',{staticClass:"carousel-control-next-icon",attrs:{"aria-hidden":"true"}})])]],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }